import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const LOGO = process.env.PUBLIC_URL + "/yanz.png";

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <motion.nav
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            className={`fixed w-full z-50 transition-all duration-300 ${
                isScrolled
                    ? "bg-dark-500/80 backdrop-blur-sm"
                    : "bg-transparent"
            }`}
        >
            <div className="container mx-auto px-4">
                <div className="flex justify-between items-center h-20">
                    <motion.a
                        href="/"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="flex items-center space-x-2"
                    >
                        <img
                            src={LOGO}
                            alt="Yanz-GPT"
                            className="w-10 h-10 rounded-full"
                        />
                        <span className="font-orbitron text-2xl font-bold text-white/90">
                            Yanz-GPT
                        </span>
                    </motion.a>

                    <div className="hidden md:flex space-x-8">
                        {["Features", "About", "Team", "Contact"].map(
                            (item) => (
                                <motion.a
                                    key={item}
                                    href={`#${item.toLowerCase()}`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="text-white/70 hover:text-white/90 transition-colors"
                                >
                                    {item}
                                </motion.a>
                            ),
                        )}
                    </div>
                </div>
            </div>
        </motion.nav>
    );
};

export default Navbar;
