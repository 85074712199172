/* eslint-disable */
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useToast } from "../utils/Toast";
import useAuth from "../hooks/useAuth";
import { login } from "../components/api";

const Login = () => {
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const { showToast } = useToast();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const validateForm = () => {
        let pwRegex = /^(?=.*[a-zA-Z])(?=.*\d).{8,}$/;
        if (username.length < 6) {
            showToast("Username must be at least 6 characters long", "error");
            return false;
        }
        if (password.length < 8 || !pwRegex.test(password)) {
            showToast(
                "Password must be at least 8 characters long, contain a number, and a special symbol",
                "error"
            );
            return false;
        }
        return true;
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            return;
        }
        try {
            let data = {
                username: username,
                password: password,
            };

            let loggedin = await login(data);

            if (loggedin?.status) {
                showToast(loggedin.message, "success");
                setAuth(loggedin.data);
                setTimeout(() => {
                    navigate("/chat");
                }, 2000);
            } else {
                showToast(loggedin.message, "error");
            }
        } catch (e) {
            console.error(e);
            showToast("Username Atau Password Salah", "error");
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-dark-500 p-4">
            <div className="w-full max-w-md rounded-3xl shadow-2xl border border-white/20 p-8 relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-tr from-accent-500/10 to-accent-600/10 opacity-30 animate-gradient-x -z-10"></div>

                <div className="flex justify-center mb-8">
                    <div className="bg-white/10 p-4 rounded-full shadow-lg border border-white/20">
                        <img
                            src="/yanz.png"
                            alt="Logo YanzGPT"
                            className="w-12 h-12"
                        />
                    </div>
                </div>

                <h2 className="text-4xl font-bold mb-2 text-center text-white bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
                    Welcome Back
                </h2>
                <p className="text-center text-white/60 mb-8">
                    Login your account to continue
                </p>

                <form className="space-y-4" onSubmit={handleLogin}>
                    <div>
                        <label
                            htmlFor="username"
                            className="block text-white/80 mb-2"
                        >
                            Username
                        </label>
                        <input
                            type="text"
                            id="username"
                            name="username"
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full px-4 py-3 bg-white/10 text-white border border-white/20 rounded-xl focus:outline-none focus:ring-2 focus:ring-accent-500"
                            placeholder="Enter your username"
                        />
                    </div>

                    <div>
                        <label
                            htmlFor="password"
                            className="block text-white/80 mb-2"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-4 py-3 bg-white/10 text-white border border-white/20 rounded-xl focus:outline-none focus:ring-2 focus:ring-accent-500"
                            placeholder="Enter your password"
                        />
                    </div>

                    <button
                        type="submit"
                        className="mt-3 w-full px-4 py-3 text-white rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 transition duration-300"
                    >
                        Login
                    </button>
                </form>
                <div className="mt-4 pb-3 flex justify-center items-center cursor-pointer text-white/60 text-sm">
                    <p>
                        Don't have account?{" "}
                        <Link
                            to="/register"
                            className="font-normal hover:text-slate-800 underline"
                        >
                            Register
                        </Link>
                        .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;
