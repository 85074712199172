import React, { useState } from "react";
import { X } from "lucide-react";
import "../../styles/ChatInput.css";

export const ChatInput = ({
    inputMessage,
    setInputMessage,
    selectedFile,
    setSelectedFile,
    isPro,
    setIsPro,
    handleSendMessage,
    fileInputRef,
    handleFileSelect,
    setQboxShow,
    imageUrl,
    processMsg,
    isAuth,
}) => {
    const [isInputed, setIsInputed] = useState(false);

    const handleFocus = () => {
        setIsInputed(true);
        document.getElementById("randomQ")?.classList?.add("closing");
        document.getElementById("QLogo")?.classList?.add("QLclose");
        document.getElementById("Qbox")?.classList?.add("qClose");
    };

    const handleBlur = () => {
        if (inputMessage.length > 0) {
            setIsInputed(true);
        } else {
            setIsInputed(false);
        }
        document.getElementById("randomQ")?.classList?.remove("closing");
        document.getElementById("QLogo")?.classList?.remove("QLclose");
        document.getElementById("Qbox")?.classList?.remove("qClose");
    };
    return (
        <div className="fixed lg:left-[140px] bottom-0 left-0 right-0 w-full input-container">
            <div className="max-w-[100%] md:max-w-2xl mx-auto p-2">
                {selectedFile && (
                    <div className="mb-2">
                        <div className="relative inline-block">
                            <div className="w-20 h-20 rounded-lg overflow-hidden">
                                <img
                                    src={selectedFile}
                                    alt="Preview"
                                    className="w-full h-full object-cover"
                                />
                                <button
                                    onClick={() => setSelectedFile(null)}
                                    className="absolute top-1 right-1 bg-red-500/80 text-white rounded-full p-1"
                                >
                                    <X className="w-2.5 h-2.5" />
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                <div
                    className={`${
                        isInputed ? "rounded-3xl" : "rounded-full"
                    } transition-all ease-in-out delay-150 chat-input  p-2`}
                >
                    <div
                        className={`${
                            isInputed ? "items-end" : "items-center"
                        } justify-between h-auto flex gap-2`}
                    >
                        {isPro && (
                            <button
                                onClick={() => fileInputRef.current?.click()}
                                className="btn-file p-1.5 hover:bg-white/10 rounded-md"
                            >
                                <i className="fa-solid fa-paperclip w-5 h-5"></i>
                            </button>
                        )}
                        {isPro && (
                            <input
                                ref={fileInputRef}
                                type="file"
                                className="hidden"
                                onChange={handleFileSelect}
                                accept="image/*"
                            />
                        )}
                        <textarea
                            value={inputMessage}
                            onFocus={() => {
                                handleFocus();
                            }}
                            onBlur={() => {
                                handleBlur();
                            }}
                            onChange={(e) => {
                                setInputMessage(e.target.value);
                            }}
                            placeholder="Ask anything..."
                            className="resize-none bg-transparent border-0 focus:ring-0 text-sm text-gray-200 placeholder-gray-400 outline-none py-1 scrollbar-thumb-gray-600 scrollbar-track-transparent"
                        />

                        <div className="flex items-center gap-2 pl-2 pro-mode">
                            {isAuth && (
                                <label
                                    htmlFor="proMode"
                                    className="flex items-center gap-1"
                                >
                                    <div className="pro-check w-9 h-5 rounded-full relative">
                                        <input
                                            type="checkbox"
                                            checked={isPro}
                                            onChange={() => setIsPro(!isPro)}
                                            id="proMode"
                                            className="hidden"
                                        />
                                        <span
                                            className={`absolute left-0.5 top-0.5 w-3.5 h-3.5 rounded-full transition-all duration-300 ${
                                                isPro
                                                    ? "translate-x-4 check-active"
                                                    : "translate-x-0 bg-gray-400"
                                            }`}
                                        />
                                    </div>
                                    <span className="text-xs pro-label">
                                        Pro
                                    </span>
                                </label>
                            )}
                            <button
                                onClick={() => {
                                    handleSendMessage();
                                    setQboxShow(false);
                                    console.log(processMsg);
                                }}
                                disabled={
                                    (!inputMessage.trim() && !imageUrl) ||
                                    processMsg
                                } // Tambahkan pengecekan processMsg
                                className={`btn-send p-1.5 rounded-md ${
                                    (!inputMessage.trim() && !imageUrl) ||
                                    processMsg
                                        ? "bg-gray-700 text-gray-500"
                                        : "send-active text-white"
                                }`}
                            >
                                {processMsg ? (
                                    <i className="fa-solid fa-spinner fa-spin"></i>
                                ) : (
                                    <i className="fa-solid fa-arrow-right"></i>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
