import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

const Hero = () => {
    return (
        <section className="min-h-screen flex items-center justify-center relative overflow-hidden bg-dark-500">
            <div className="absolute inset-0 z-0">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(42,74,106,0.15)_0%,transparent_60%)]"></div>
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(106,42,106,0.1)_0%,transparent_60%)]"></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <div className="text-center">
                    <motion.h1
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8 }}
                        className="font-orbitron text-6xl md:text-7xl font-bold mb-8 text-white/90"
                    >
                        Yanz-GPT
                    </motion.h1>

                    <motion.p
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.2 }}
                        className="text-xl md:text-2xl text-white/70 mb-12 max-w-3xl mx-auto"
                    >
                        Experience the next generation of AI with advanced
                        language processing and intelligent responses that adapt
                        to your needs.
                    </motion.p>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, delay: 0.4 }}
                        className="flex flex-col sm:flex-row gap-6 justify-center"
                    >
                        <motion.a
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            href="/chat"
                            className="px-8 py-4 bg-accent-500/20 hover:bg-accent-500/30 backdrop-blur-sm rounded-lg 
                        text-white/90 font-medium border border-accent-500/20 hover:border-accent-500/30 
                        transition-all duration-300 shadow-subtle hover:shadow-glow"
                        >
                            Get Started
                        </motion.a>
                        <Link
                            to="learn-more"
                            smooth={true}
                            duration={800}
                            offset={-70}
                        >
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-8 py-4 bg-dark-400/50 hover:bg-dark-400/70 backdrop-blur-sm rounded-lg 
                          text-white/80 font-medium border border-white/10 hover:border-white/20 
                          transition-all duration-300 cursor-pointer w-full sm:w-auto"
                            >
                                Learn More
                            </motion.button>
                        </Link>
                    </motion.div>
                </div>
            </div>

            {/* Subtle floating particles */}
            {[...Array(15)].map((_, i) => (
                <motion.div
                    key={i}
                    className="absolute w-1 h-1 bg-accent-500/20 rounded-full"
                    style={{
                        left: `${Math.random() * 100}%`,
                        top: `${Math.random() * 100}%`,
                    }}
                    animate={{
                        y: [0, -20, 0],
                        opacity: [0.2, 0.5, 0.2],
                    }}
                    transition={{
                        duration: 3 + Math.random() * 2,
                        repeat: Infinity,
                        ease: "easeInOut",
                        delay: Math.random() * 2,
                    }}
                />
            ))}
        </section>
    );
};

export default Hero;
