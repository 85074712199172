import { createContext, useState, useEffect } from "react";
import { getAuthCookie, removeAuthCookie, setAuthCookie } from "../lib/auth.js";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const savedAuth = getAuthCookie();
        return savedAuth ? JSON.parse(savedAuth) : null;
    });

    // Simpan auth ke localStorage setiap kali auth berubah
    useEffect(() => {
        setAuthCookie(JSON.stringify(auth));
    }, [auth]);

    // fungsi logout

    const logout = () => {
        removeAuthCookie();
        setAuth(null);
    };

    // Validasi expired token
    useEffect(() => {
        if (auth?.token) {
            const tokenPayload = getAuthCookie();
            if (tokenPayload === undefined) {
                removeAuthCookie();
                setAuth(null);
            }
        }
    }, [auth]);

    return (
        <AuthContext.Provider value={{ auth, setAuth, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
