import React from "react";
import { motion } from "framer-motion";

const About = () => {
    const LOGO = process.env.PUBLIC_URL + "/yanzgpt.jpg";
    const stats = [
        { number: "20M+", label: "Active Users" },
        { number: "99.9%", label: "Uptime" },
        { number: "150+", label: "Countries" },
        { number: "24/7", label: "Support" },
    ];

    const features = [
        {
            icon: "fa-brain",
            title: "Advanced AI",
            description:
                "Powered by state-of-the-art language models and deep learning algorithms",
        },
        {
            icon: "fa-bolt",
            title: "Real-time Processing",
            description:
                "Lightning-fast responses with optimized processing systems",
        },
        {
            icon: "fa-globe",
            title: "Global Reach",
            description: "Supporting multiple languages and cultures worldwide",
        },
    ];

    return (
        <section
            id="about"
            className="py-24 relative overflow-hidden bg-dark-500"
        >
            {/* Animated Background */}
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(42,74,106,0.08)_0%,transparent_60%)]"></div>
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(106,42,106,0.05)_0%,transparent_60%)]"></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                    {/* Left Column - Main Content */}
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                    >
                        <h2 className="font-orbitron text-4xl font-bold mb-6 text-white/90">
                            Revolutionizing AI Interaction
                        </h2>

                        <p className="text-white/70 text-lg mb-8 leading-relaxed">
                            Yanz-GPT represents a breakthrough in artificial
                            intelligence, combining advanced language processing
                            with intuitive user experience. Our commitment to
                            innovation drives us to continuously enhance and
                            evolve our technology.
                        </p>

                        <div className="space-y-6 mb-8">
                            {features.map((feature, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.1 }}
                                    className="flex items-start space-x-4"
                                >
                                    <div
                                        className="w-12 h-12 flex items-center justify-center rounded-xl 
                                bg-accent-500/10 group-hover:bg-accent-500/20 transition-colors shrink-0"
                                    >
                                        <i
                                            className={`fas ${feature.icon} text-2xl text-white/70`}
                                        ></i>
                                    </div>
                                    <div>
                                        <h3 className="text-xl font-bold text-white/90 mb-2">
                                            {feature.title}
                                        </h3>
                                        <p className="text-white/70">
                                            {feature.description}
                                        </p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>

                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="px-8 py-4 bg-accent-500/20 hover:bg-accent-500/30 rounded-lg 
                       text-white/90 font-medium border border-accent-500/20 
                       hover:border-accent-500/30 transition-all duration-300"
                        >
                            Discover More
                        </motion.button>
                    </motion.div>

                    {/* Right Column - Stats & Image */}
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{ duration: 0.6 }}
                        className="relative"
                    >
                        {/* Stats Grid */}
                        <div className="grid grid-cols-2 gap-6 mb-8">
                            {stats.map((stat, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    whileInView={{ opacity: 1, y: 0 }}
                                    viewport={{ once: true }}
                                    transition={{ delay: index * 0.1 }}
                                    className="bg-dark-400/50 backdrop-blur-sm border border-accent-500/10 
                           hover:border-accent-500/20 rounded-xl p-6 text-center
                           hover:shadow-lg hover:shadow-accent-500/5 transition-all duration-300"
                                >
                                    <h4 className="text-3xl font-bold text-white/90 mb-2">
                                        {stat.number}
                                    </h4>
                                    <p className="text-white/70">
                                        {stat.label}
                                    </p>
                                </motion.div>
                            ))}
                        </div>

                        {/* Image Section */}
                        <motion.div
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                            className="relative rounded-xl overflow-hidden aspect-video"
                        >
                            <div className="absolute inset-0 bg-gradient-to-r from-accent-500/10 to-accent-600/10 backdrop-blur-sm"></div>
                            <img
                                src={LOGO}
                                alt="Yanz-GPT Interface"
                                className="w-full h-full object-cover rounded-xl opacity-80 hover:opacity-100 transition-opacity duration-300"
                            />

                            {/* Floating Elements */}
                            <div className="absolute inset-0 pointer-events-none">
                                {[...Array(5)].map((_, i) => (
                                    <motion.div
                                        key={i}
                                        className="absolute w-2 h-2 bg-accent-500/20 rounded-full"
                                        style={{
                                            left: `${Math.random() * 100}%`,
                                            top: `${Math.random() * 100}%`,
                                        }}
                                        animate={{
                                            y: [0, -20, 0],
                                            opacity: [0.2, 0.5, 0.2],
                                        }}
                                        transition={{
                                            duration: 3 + Math.random() * 2,
                                            repeat: Infinity,
                                            ease: "easeInOut",
                                            delay: Math.random() * 2,
                                        }}
                                    />
                                ))}
                            </div>
                        </motion.div>
                    </motion.div>
                </div>

                {/* Mission Statement */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="mt-16 text-center max-w-3xl mx-auto"
                >
                    <h3 className="text-2xl font-bold text-white/90 mb-4">
                        Our Mission
                    </h3>
                    <p className="text-white/70 text-lg">
                        We are committed to democratizing access to advanced AI
                        technology, making it accessible, reliable, and
                        beneficial for users worldwide. Through continuous
                        innovation and dedication to excellence, we strive to
                        push the boundaries of what's possible in AI
                        interaction.
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default About;
