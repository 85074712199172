import React, { useState, useEffect, useRef, useCallback } from "react";
import {
    sendMessage,
    uploadImages,
    checkPrompt,
    pushChats,
    createNewChats,
    getAllChats,
} from "./api";
import { ChatMessage } from "./ui/ChatMessage";
import {
    SkeletonLoader,
    ImageLoader,
    DefaultLoader,
} from "./ui/SkeletonLoader";
import { ChatHeader } from "./ui/ChatHeader";
import { ChatInput } from "./ui/ChatInput";
import { Sidebar } from "./ui/Sidebar";
import useAuth from "../hooks/useAuth";
import { Modal } from "./ui/Modal";

import AnimatedBackground from "./ui/AnimatedBackground";
import Qbox from "./ui/Qbox";

const ChatApp = () => {
    const { auth } = useAuth();
    const [chatId, setChatId] = useState("");
    const [chat, setChat] = useState([]);
    const [messages, setMessages] = useState([]);
    const [qboxShow, setQboxShow] = useState(true);
    const [imageUrl, setImageUrl] = useState(null);
    const [processMsg, setProcessMsg] = useState(false);
    const [loadingType, setLoadingType] = useState("default");
    const [loadingImageType, setLoadingImageType] = useState("single");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [instructions, setInstructions] = useState(true);

    const [inputMessage, setInputMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isPro, setIsPro] = useState(auth?.isPro || false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [darkMode, setDarkMode] = useState(() => {
        const savedMode = localStorage.getItem("darkMode");
        return savedMode ? JSON.parse(savedMode) : false;
    });

    const chatBoxRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (auth) {
            setInstructions(false);
        } else {
            setInstructions(true);
        }
    }, [auth]);

    useEffect(() => {
        const setVH = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
        };
        setVH();
        window.addEventListener("resize", setVH);
        window.addEventListener("orientationchange", setVH);
        return () => {
            window.removeEventListener("resize", setVH);
            window.removeEventListener("orientationchange", setVH);
        };
    }, []);

    useEffect(() => {
        localStorage.setItem("darkMode", JSON.stringify(darkMode));
        darkMode
            ? document.documentElement.classList.add("dark")
            : document.documentElement.classList.remove("dark");
    }, [darkMode]);

    const formatCitations = (text) => {
        const citationRegex = /\[(\d+)\]\s*(https?:\/\/[^\s]+)/g;

        const citationMap = {};
        let match;

        while ((match = citationRegex.exec(text)) !== null) {
            citationMap[match[1]] = match[2];
        }

        let mainText = text.split("Citations:")[0];
        mainText = mainText.replace(
            /\[(\d+)\]/g,
            (match, number) => `[[${number}]](${citationMap[number]})`
        );

        return mainText.trim();
    };

    const get_all_chat = useCallback(async () => {
        let allChats = await getAllChats(auth?.id, auth?.token);
        if (allChats?.status) {
            setChat(allChats?.data);
        }
    }, [getAllChats, setChat]);

    const handleSendMessage = async () => {
        if (!inputMessage.trim() && !selectedFile) return;

        let userMessage;

        if (imageUrl !== null) {
            userMessage = {
                type: "user",
                content: {
                    text: inputMessage,
                    image: imageUrl,
                },
            };
        } else {
            userMessage = {
                type: "user",
                content: inputMessage,
            };
        }

        setMessages((prev) => [...prev, userMessage]);
        let currentChatId;
        if (auth) {
            if (chatId === "") {
                let newChat = await createNewChats(
                    auth.id,
                    auth.token,
                    inputMessage
                );
                if (newChat.status) {
                    const newChatId = newChat.data.chat_id;
                    await setChatId(newChatId);
                    currentChatId = newChatId;
                    await pushChats(
                        auth.id,
                        newChatId,
                        auth.token,
                        userMessage
                    );
                    await get_all_chat();
                }
            } else {
                await pushChats(auth.id, chatId, auth.token, userMessage);
            }
        }

        setImageUrl(null);
        setProcessMsg(true);
        setIsLoading(true);
        setInputMessage("");
        setSelectedFile(null);

        let checkedPrompt = await checkPrompt(inputMessage);
        if (checkedPrompt) {
            setLoadingType(checkedPrompt?.cmd);
            setLoadingImageType("multi");
        }

        try {
            const messageHistory = messages.map((msg) => {
                let message = {
                    role: msg.type === "user" ? "user" : "assistant",
                    content: msg.content,
                };
                return message;
            });

            let newMessage = {
                role: "user",
                content: inputMessage,
            };

            messageHistory.push(newMessage);

            let response = await sendMessage(messageHistory, imageUrl, isPro);
            let newBotMessage = {
                type: "bot",
                image: response?.image,
                content: formatCitations(response.content),
            };
            setMessages((prev) => [...prev, newBotMessage]);

            if (auth) {
                if (chatId === "") {
                    await pushChats(
                        auth.id,
                        currentChatId,
                        auth.token,
                        newBotMessage
                    );
                } else {
                    await pushChats(auth.id, chatId, auth.token, newBotMessage);
                }
            }
        } catch (error) {
            setMessages((prev) => [
                ...prev,
                {
                    type: "bot",
                    content:
                        "Maaf, terjadi kesalahan dalam koneksi. Silakan coba lagi.",
                    isError: true,
                },
            ]);
        } finally {
            setIsLoading(false);
            setLoadingType("default");
        }
    };

    const handleFileSelect = async (event) => {
        const file = event.target.files[0];

        if (file) {
            setSelectedFile(URL.createObjectURL(file));

            const result = await uploadImages(file);

            if (result && result.image) {
                setImageUrl(result.image);
            }
        }
    };

    return (
        <div className="h-screen w-full bg-slate-900 text-gray-200">
            <div
                className="relative h-full flex flex-col overflow-hidden"
                style={{ height: "calc(var(--vh, 1vh) * 100)" }}
            >
                <div className="fixed inset-0 pointer-events-none">
                    <AnimatedBackground />
                </div>
                <div className="relative flex flex-col h-full z-10">
                    <ChatHeader
                        sidebarOpen={sidebarOpen}
                        setSidebarOpen={setSidebarOpen}
                        isAuth={auth}
                    />
                    <Sidebar
                        open={sidebarOpen}
                        messages={messages}
                        setMessages={setMessages}
                        setQbox={setQboxShow}
                        setChatId={setChatId}
                        chat={chat}
                        setChat={setChat}
                        get_all_chat={get_all_chat}
                    />
                    {instructions && (
                        <Modal
                            title="Selamat Datang Kembali"
                            onHide={() => setInstructions(false)}
                        >
                            <p>
                                Silahkan Masuk untuk menikmati respon yang lebih
                                bagus, upload gambar, pencarian dan coba
                                beberapa model lain nya
                            </p>
                            <div className="mt-4 pt-[15px] border-t border-t-gray-800 flex flex-row items-center gap-3">
                                <button
                                    onClick={() =>
                                        (window.location.href = "/login")
                                    }
                                    className="w-full flex items-center justify-center text-white font-medium rounded-full text-sm px-4 py-2 text-center bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 transition duration-300"
                                >
                                    Masuk
                                </button>
                                <button
                                    onClick={() => setInstructions(false)}
                                    className="w-full px-4 py-2 text-sm font-medium rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500/70 hover:text-white bg-white/10 border border-white/20 hover:border-white/30"
                                >
                                    Tetap Keluar
                                </button>
                            </div>
                        </Modal>
                    )}

                    <main className="lg:ml-64 mt-[65px] flex-1 relative overflow-hidden pt-4">
                        <div
                            ref={chatBoxRef}
                            id="chatBox"
                            className="pl-2 pr-2 pt-4 absolute inset-0 overflow-y-auto overflow-x-hidden scrollbar-thumb-gray-600 scrollbar-track-transparent pb-32"
                        >
                            {messages.map((message, index) => (
                                <ChatMessage
                                    setProcessMsg={setProcessMsg}
                                    key={index}
                                    {...message}
                                />
                            ))}

                            {qboxShow && (
                                <Qbox setInputMessage={setInputMessage} />
                            )}

                            {isLoading &&
                                (loadingType === "text_completion" ? (
                                    <SkeletonLoader />
                                ) : loadingType === "imaging" ? (
                                    <ImageLoader
                                        loadingType={loadingImageType}
                                    />
                                ) : loadingType === "searching" ? (
                                    <SkeletonLoader />
                                ) : (
                                    <DefaultLoader />
                                ))}
                        </div>
                    </main>

                    <div className="absolute bottom-0 sm:left-0 sm:right-0 lg:left-[256px]">
                        <ChatInput
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            isPro={isPro}
                            setIsPro={setIsPro}
                            handleSendMessage={handleSendMessage}
                            fileInputRef={fileInputRef}
                            handleFileSelect={handleFileSelect}
                            setQboxShow={setQboxShow}
                            imageUrl={imageUrl}
                            processMsg={processMsg}
                            isAuth={auth}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatApp;
