import React, { useState, useEffect, useRef } from "react";
import { Smile, Share2, Bookmark } from "lucide-react";
import { GlassCard } from "./GlassCard";
import MarkdownRenderer from "./MarkdownRenderer";
import { ImageGenerationLoader } from "./SkeletonLoader";
import "../../styles/ChatMessage.css";

export const ChatMessage = ({
    type,
    image,
    content,
    isError,
    setProcessMsg,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [displayedMarkdown, setDisplayedMarkdown] = useState("");
    const [imageLoaded, setImageLoaded] = useState(false);
    const messageRef = useRef(null);
    console.log("is image loaded:", imageLoaded);

    useEffect(() => {
        let chatBox = document?.getElementById("chatBox");
        function getLinkInfo() {
            document?.querySelectorAll("a")?.forEach(function (link) {
                link.style.color = "#14caff";
                if (
                    (link.textContent.includes("[") &&
                        link.textContent.includes("]")) ||
                    (link.textContent.includes("[[") &&
                        link.textContent.includes("]]"))
                ) {
                    link.textContent = link.textContent
                        .replace("[", "")
                        .replace("]", "");
                    link.textContent = link.textContent
                        .replace("[[", "")
                        .replace("]]", "");
                    link.style.textDecoration = "none";
                    link.style.backgroundColor = "#ffffff";
                    link.style.borderRadius = "50px";
                    link.style.paddingLeft = "4.5px";
                    link.style.paddingRight = "4.5px";
                    link.style.color = "#14caff";
                    link.style.margin = "0";
                    link.style.verticalAlign = "top";
                    link.style.fontSize = "10px";
                    link.style.margin = "3px";
                }
            });
        }

        if (type !== "user" && content) {
            const words = content.split(" ");
            let index = 0;

            const typingInterval = setInterval(() => {
                setDisplayedMarkdown(
                    (prev) => prev + (prev ? " " : "") + (words[index] || ""),
                );
                index += 1;
                chatBox.scroll({
                    top: chatBox.scrollHeight,
                    behavior: "smooth",
                });

                getLinkInfo();
                if (index >= words.length) {
                    clearInterval(typingInterval);
                    setProcessMsg(false);
                }
            }, 5);

            return () => clearInterval(typingInterval);
        } else {
            setDisplayedMarkdown(content || "");
        }
    }, [content, type]);

    useEffect(() => {
        if (messageRef.current) {
            messageRef.current.classList.add("animate-fade-slide-in");
        }
    }, []);

    return (
        <div
            ref={messageRef}
            className="flex flex-col w-full px-2 md:px-4 mb-2"
        >
            <div
                className={`flex items-start ${type === "user" ? "justify-end" : "justify-start"}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <div
                    className={`max-w-[100%] md:max-w-[60%] flex flex-col ${
                        type === "user" ? "items-end" : "items-start"
                    }`}
                >
                    <GlassCard
                        className={`rounded-xl p-2 ${
                            type === "user" ? "chat-user text-white" : "chat-ai"
                        } ${isError ? "border-red-500 border" : ""}`}
                    >
                        <div
                            className={`msg whitespace-pre-wrap break-words overflow-hidden ${
                                type === "user"
                                    ? "user text-white"
                                    : "text-gray-200"
                            }`}
                        >
                            {type !== "user" ? (
                                <>
                                    <MarkdownRenderer
                                        markdownText={displayedMarkdown}
                                    />
                                    {image && (
                                        <ImageGenerationLoader
                                            loadingType="multi"
                                            image={image}
                                        />
                                    )}
                                </>
                            ) : typeof content === "object" ? (
                                <>
                                    <div className="image-wrapper">
                                        {!imageLoaded && (
                                            <div className="image-loader">
                                                <i className="fa-regular fa-image"></i>
                                            </div>
                                        )}
                                        <img
                                            src={content.image}
                                            alt="content"
                                            className={`image-content ${
                                                !imageLoaded ? "hidden" : ""
                                            }`}
                                            onLoad={() => {
                                                console.log(
                                                    "image loaded: ",
                                                    content.image,
                                                );
                                                setImageLoaded(true);
                                            }}
                                        />
                                    </div>
                                    <br />
                                    {content.text}
                                </>
                            ) : (
                                content
                            )}
                        </div>
                    </GlassCard>

                    {isHovered && (
                        <div className="flex mt-1 space-x-0.5">
                            <button className="p-0.5 hover:bg-white/10 rounded-full">
                                <Smile className="w-3 h-3 text-gray-400" />
                            </button>
                            <button className="p-0.5 hover:bg-white/10 rounded-full">
                                <Share2 className="w-3 h-3 text-gray-400" />
                            </button>
                            <button className="p-0.5 hover:bg-white/10 rounded-full">
                                <Bookmark className="w-3 h-3 text-gray-400" />
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
