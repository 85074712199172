// src/components/sections/Team.jsx
import React from "react";
import { motion } from "framer-motion";

const VerifiedBadge = () => (
    <svg
        viewBox="0 0 18 18"
        className="w-5 h-5 absolute -right-1 -top-1"
        preserveAspectRatio="xMidYMid meet"
    >
        <polygon
            fill="rgba(0, 218, 96, 0.2)"
            points="9,16 7.1,16.9 5.8,15.2 3.7,15.1 3.4,13 1.5,12 2.2,9.9 1.1,8.2 2.6,6.7 2.4,4.6 4.5,4 5.3,2 7.4,2.4 9,1.1 10.7,2.4 12.7,2 13.6,4 15.6,4.6 15.5,6.7 17,8.2 15.9,9.9 16.5,12 14.7,13 14.3,15.1 12.2,15.2 10.9,16.9"
        />
        <polygon
            fill="#FFFFFF"
            points="13.1,7.3 12.2,6.5 8.1,10.6 5.9,8.5 5,9.4 8,12.4"
        />
    </svg>
);

const Team = () => {
    let logo = process.env.PUBLIC_URL + "/yanz.png";
    const teamMembers = [
        {
            name: "Yanz Dev",
            role: "CEO & Founder",
            description:
                "Inovator terdepan dalam teknologi AI yang bertujuan untuk merevolusi pengalaman pencarian dan interaksi pengguna.",
            image: logo,
            socialLinks: [
                { icon: "github", url: "https://github.com/YanzBotz" },
                {
                    icon: "instagram",
                    url: "https://www.instagram.com/yanzbotz_",
                },
                { icon: "youtube", url: "https://www.youtube.com/@YanzBotz" },
                { icon: "facebook", url: "https://www.facebook.com/yanzbotzx" },
                {
                    icon: "linkedin",
                    url: "https://www.linkedin.com/in/yanzdev",
                },
                { icon: "link", url: "https://temansocial.com/yanzdev" },
            ],
            gradient: "from-accent-500/10 via-accent-400/10 to-accent-500/10",
        },
        {
            name: "Sanzy Dev",
            role: "Lead Frontend Developer & CTO",
            description:
                "Memimpin pengembangan frontend dan teknologi terbaru, bertanggung jawab untuk inovasi UI/UX, arsitektur sistem, dan pengembangan fitur-fitur utama platform.",
            image: "https://github.com/sanzydev.png",
            socialLinks: [
                { icon: "github", url: "https://github.com/sanzydev" },
                { icon: "instagram", url: "https://www.instagram.com/" },
                { icon: "youtube", url: "https://www.youtube.com/@SANZYYT" },
                { icon: "link", url: "https://temansocial.com/sanzydev" },
            ],
            gradient: "from-accent-400/10 via-accent-300/10 to-accent-400/10",
            featured: true,
        },
        {
            name: "APDev",
            role: "CO-FOUNDER",
            description:
                "Bertanggung jawab dalam pengembangan Performa, Fitur, UI, dan Sistem dari Yanz-GPT.",
            image: "https://github.com/APdev93.png",
            socialLinks: [
                { icon: "github", url: "https://github.com/APdev93" },
                { icon: "instagram", url: "https://www.instagram.com/apdev93" },
                {
                    icon: "youtube",
                    url: "https://www.youtube.com/@ardiantdrofficial",
                },
                { icon: "link", url: "https://temansocial.com/apdev93" },
            ],
            gradient: "from-accent-400/10 via-accent-300/10 to-accent-400/10",
            featured: true,
        },
        {
            name: "Rifza Pratama",
            role: "CEO Partner",
            description:
                "Berkontribusi dalam pengembangan API dan WhatsApp Bot AI, membantu meningkatkan fungsionalitas Yanz-GPT.",
            image: "https://github.com/Rifza123.png",
            socialLinks: [
                { icon: "github", url: "https://github.com/Rifza123" },
                {
                    icon: "instagram",
                    url: "https://www.instagram.com/rifza.p.p",
                },
                { icon: "youtube", url: "https://www.youtube.com/@rifza" },
                { icon: "link", url: "https://temansocial.com/azfir" },
            ],
            gradient: "from-accent-500/10 via-accent-400/10 to-accent-500/10",
        },
        {
            name: "Arifzyn",
            role: "Security & Integration Lead",
            description:
                "Partner utama dalam pengembangan keamanan, integrasi API, dan kolaborasi internasional.",
            image: "https://github.com/Arifzyn19.png",
            socialLinks: [
                { icon: "github", url: "https://github.com/Arifzyn19" },
                {
                    icon: "instagram",
                    url: "https://www.instagram.com/arifzxa19",
                },
                { icon: "youtube", url: "https://www.youtube.com/@arifzxa19" },
                { icon: "link", url: "https://temansocial.com/arifzxa19" },
            ],
            gradient: "from-accent-500/10 via-accent-400/10 to-accent-500/10",
        },
        {
            name: "Siputzx Production",
            role: "Integration Partner",
            description:
                "Mitra partner dalam pengembangan integrasi API dan kolaborasi antar Lumin AI dan Yanz-GPT.",
            image: "https://github.com/siputzx.png",
            socialLinks: [
                { icon: "github", url: "https://github.com/siputzx" },
                {
                    icon: "instagram",
                    url: "https://www.instagram.com/siputzx_",
                },
                {
                    icon: "link",
                    url: "https://siputzx.my.id",
                    label: "Portfolio",
                },
                {
                    icon: "link",
                    url: "https://luminai.my.id",
                    label: "Lumin AI",
                },
            ],
            gradient: "from-accent-400/10 via-accent-300/10 to-accent-400/10",
        },
    ];

    return (
        <section className="py-24 relative overflow-hidden bg-dark-500">
            {/* Background Effects */}
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_20%_20%,rgba(42,74,106,0.1)_0%,transparent_60%)]"></div>
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_80%_80%,rgba(106,42,106,0.05)_0%,transparent_60%)]"></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-16"
                >
                    <h2 className="font-orbitron text-4xl font-bold mb-6 text-white/90">
                        Our Team
                    </h2>
                    <p className="text-white/70 text-lg max-w-2xl mx-auto">
                        Meet the talented individuals behind Yanz-GPT who make
                        it all possible
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {teamMembers.map((member, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                            whileHover={{ translateY: -8 }}
                            className={`bg-gradient-to-br ${member.gradient} p-6 rounded-xl
                         backdrop-blur-sm border border-accent-500/10
                         hover:border-accent-500/20 transition-all duration-300
                         group hover:shadow-subtle`}
                        >
                            <div className="relative inline-block mb-6">
                                <motion.img
                                    src={member.image}
                                    alt={member.name}
                                    className="w-24 h-24 rounded-full object-cover border-2 border-accent-500/20"
                                    whileHover={{ scale: 1.05 }}
                                />
                                <VerifiedBadge />
                            </div>

                            <h3 className="text-xl font-bold text-white/90 mb-2">
                                {member.name}
                            </h3>
                            <p className="text-accent-400 font-medium mb-3">
                                {member.role}
                            </p>
                            <p className="text-white/70 text-sm mb-6">
                                {member.description}
                            </p>

                            <div className="flex justify-center gap-4">
                                {member.socialLinks.map((link, i) => (
                                    <motion.a
                                        key={i}
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        whileHover={{ scale: 1.2, y: -2 }}
                                        whileTap={{ scale: 0.95 }}
                                        className="text-white/60 hover:text-white/90 transition-colors"
                                    >
                                        <i
                                            className={`fab fa-${link.icon} text-xl`}
                                        ></i>
                                    </motion.a>
                                ))}
                            </div>
                        </motion.div>
                    ))}
                </div>

                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    className="text-center mt-16 text-white/60 max-w-2xl mx-auto"
                >
                    <p>
                        Tanpa dedikasi dan kerja keras dari para kontributor
                        ini, Yanz-GPT tidak akan menjadi seperti sekarang. Kami
                        berkomitmen untuk terus berinovasi dan memberikan yang
                        terbaik bagi pengguna!
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default Team;
