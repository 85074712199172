export const Modal = ({ title, onHide, children }) => {
    return (
        <div className="w-full h-full fixed top-0 left-0 z-50 bg-[#131a2f09] flex items-center justify-center">
            <div className="w-full h-full flex items-center justify-center bg-[#131a2f7d]">
                <div className="w-[95%] border border-[#17243a] max-w-[500px] bg-[#131a2f] rounded-lg shadow-lg overflow-hidden">
                    <div className="bg-[#17243a] text-white p-4 font-bold flex justify-between items-center">
                        <span>{title}</span>
                        <button
                            className="text-white p-2 text-sm"
                            onClick={onHide}
                        >
                            ✕
                        </button>
                    </div>
                    <div className="p-4 text-white">{children}</div>
                </div>
            </div>
        </div>
    );
};
