import { useState, useEffect } from "react";
import { createNewChats, deleteChats } from "../api.js";
import useAuth from "../../hooks/useAuth";

export const Sidebar = ({
    open,
    messages,
    setMessages,
    setQbox,
    setChatId,
    chat,
    setChat,
    get_all_chat,
}) => {
    const { auth } = useAuth();
    const [activeChats, setActiveChats] = useState("");

    const [dropDownShow, setDropDownShow] = useState("");

    useEffect(() => {
        if (auth) {
            get_all_chat();
        }
    }, [get_all_chat]);

    return (
        <aside
            id="logo-sidebar"
            className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${
                open ? "translate-x-0" : "-translate-x-full"
            } lg:translate-x-0 border-r border-[#17243a] bg-[#0f0d1f] lg:bg-[#17243a]`}
            aria-label="Sidebar"
        >
            <div className="h-full px-3 pb-4 overflow-y-auto bg-[#0f0d1f] lg:bg-[#17243a]">
                <span className="text-gray-600">Obrolan</span>
                <ul className="!m-0 !mt-1 font-medium list-none">
                    {chat.map((c, index) => {
                        return (
                            <li className="!m-0 p-1" key={index}>
                                <button
                                    onClick={() => {
                                        console.log(c);
                                        setActiveChats(c.chat_id);
                                        setChatId(c.chat_id);
                                        setMessages([]);
                                        setQbox(false);
                                        setQbox(false);
                                        setMessages(c.chat);
                                    }}
                                    className={`${
                                        activeChats === c.chat_id
                                            ? "bg-[#13102b]"
                                            : ""
                                    } w-full h-auto flex justify-between items-center p-2 text-white rounded-lg hover:bg-[#13102b]  group`}
                                >
                                    <span className="ms-3">{c.title}</span>
                                    <div
                                        className={`${
                                            activeChats === c.chat_id
                                                ? ""
                                                : "hidden"
                                        } relative`}
                                    >
                                        <button
                                            className="inline-flex items-center p-2 text-sm font-medium bg-transparent rounded-lg text-gray-400 hover:text-white hover:bg-[#1c1a2e]"
                                            type="button"
                                            onClick={() => {
                                                setDropDownShow(
                                                    dropDownShow === c.chat_id
                                                        ? ""
                                                        : c.chat_id
                                                );
                                            }}
                                        >
                                            <svg
                                                className="w-4 h-4"
                                                aria-hidden="true"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                viewBox="0 0 4 15"
                                            >
                                                <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                            </svg>
                                        </button>
                                        {dropDownShow === c.chat_id && (
                                            <div
                                                className="absolute right-0 z-20 mt-2 bg-[#17243a] divide-y rounded-lg shadow-lg w-44 divide-gray-600"
                                                onMouseLeave={() =>
                                                    setDropDownShow("")
                                                } // Tutup saat mouse keluar
                                            >
                                                <ul className="list-none !m-0 p-1 text-sm text-gray-200">
                                                    <li>
                                                        <button
                                                            className="flex w-full rounded px-4 py-2 text-gray-500 hover:bg-[#101a2a] hover:text-gray-500"
                                                            onClick={() => {
                                                                // Tambahkan logika untuk mengedit judul
                                                            }}
                                                        >
                                                            Edit Judul
                                                        </button>
                                                    </li>
                                                    <li>
                                                        <button
                                                            className="flex w-full rounded block px-4 py-2 hover:bg-[#101a2a] hover:text-white"
                                                            onClick={() => {
                                                                deleteChats(
                                                                    auth.id,
                                                                    c.chat_id,
                                                                    auth.token
                                                                ).then(() =>
                                                                    get_all_chat()
                                                                );
                                                            }}
                                                        >
                                                            Hapus Obrolan
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </aside>
    );
};
