import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

global.API_KEY = "yzgpt-sc4tlKsMRdNMecNy";
global.CDN_URL = "https://cdn.yanzgpt.my.id/upload";
global.API_URL = "https://api.yanzgpt.my.id/v1";
global.BACKEND = "https://user.yanzgpt.my.id";

const firebaseConfig = {
    apiKey: "AIzaSyCe4GhVJcXhKqUBTugMbGJsFclqCnRGQD0",
    authDomain: "yanzgpt-6ef7d.firebaseapp.com",
    projectId: "yanzgpt-6ef7d",
    storageBucket: "yanzgpt-6ef7d.firebasestorage.app",
    messagingSenderId: "635526372250",
    appId: "1:635526372250:web:5e2411c8e4cf690bf9f823",
    measurementId: "G-CNM1EEDD06",
};

const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
export const db = getFirestore(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export default app;
