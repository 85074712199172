// src/components/ui/AnimatedBackground.jsx
const AnimatedBackground = () => (
    <div
        style={{
            backgroundColor: "#0F0D1F",
        }}
        className="fixed inset-0 -z-10 overflow-hidden"
    ></div>
);

export default AnimatedBackground;
