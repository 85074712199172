import React from "react";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import ChatApp from "./components/ChatApp";
import Home from "./components/Home";
import Login from "./components/Login";
import { Register } from "./components/Register";
import { ToastProvider } from "./utils/Toast";

function App() {
	return (

			<ToastProvider>
				<Router>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/chat" element={<ChatApp />} />
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</Router>
			</ToastProvider>
	);
}

export default App;
