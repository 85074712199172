// src/components/sections/Features.jsx
import React from "react";
import { motion } from "framer-motion";

const Features = () => {
    const features = [
        {
            icon: "fa-bullseye",
            title: "High Accuracy",
            description:
                "Get precise and relevant answers powered by our advanced AI models",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-bolt",
            title: "Real-time Processing",
            description:
                "Experience lightning-fast responses with our optimized processing systems",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-globe",
            title: "Multi-language Support",
            description:
                "Communicate seamlessly in over 20+ languages with native-like understanding",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-shield-halved",
            title: "Enterprise Security",
            description:
                "Bank-grade encryption and advanced data protection protocols",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-robot",
            title: "Advanced AI Models",
            description:
                "Choose between Revolution and Legacy models for your specific needs",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-solid fa-desktop",
            // "fa-solid " or "fa-solid fa-display"
            // "fa-solid fa-desktop" or "fa-solid fa-mobile-screen-button"
            title: "Cross-platform Support",
            description:
                "Access from any device with seamless integration across platforms",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-solid fa-brain",
            // Alternative: "fa-solid fa-network-wired" or "fa-solid fa-sitemap"
            title: "Contextual Understanding",
            description:
                "Advanced context retention for more natural conversations",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-chart-line",
            title: "Data Analytics",
            description:
                "Comprehensive analytics and insights for better decision making",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-paintbrush",
            title: "Creative Assistance",
            description:
                "Help with content creation, writing, and creative tasks",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-book",
            title: "Knowledge Base",
            description: "Access to vast knowledge spanning multiple domains",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-magnifying-glass",
            title: "Semantic Search",
            description:
                "Advanced search capabilities with semantic understanding",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-code",
            title: "API Integration",
            description:
                "Easy integration with existing systems via robust API",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-gear",
            title: "Custom Training",
            description: "Train models on your specific data and use cases",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
        {
            icon: "fa-arrow-trend-up",
            title: "Scalability",
            description: "Easily scale resources based on your needs",
            gradient: "from-accent-400/20 to-accent-500/20",
        },
        {
            icon: "fa-shield",
            title: "Content Filtering",
            description: "Advanced content moderation and safety features",
            gradient: "from-accent-500/10 to-accent-600/10",
        },
    ];

    return (
        <section
            id="features"
            className="py-24 relative overflow-hidden bg-dark-500"
        >
            {/* Background Effects */}
            <div className="absolute inset-0">
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(42,74,106,0.1)_0%,transparent_60%)]"></div>
                <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_80%,rgba(106,42,106,0.05)_0%,transparent_60%)]"></div>
            </div>

            <div className="container mx-auto px-4 relative z-10">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    className="text-center mb-16"
                >
                    <h2 className="font-orbitron text-4xl font-bold mb-6 text-white/90">
                        Powerful Features
                    </h2>
                    <p className="text-white/70 text-lg max-w-2xl mx-auto">
                        Discover the extensive capabilities of Yanz-GPT that
                        make it the most advanced AI assistant platform
                    </p>
                </motion.div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {features.map((feature, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: index * 0.1 }}
                            whileHover={{ scale: 1.02 }}
                            className={`bg-gradient-to-br ${feature.gradient} p-6 rounded-xl
                         backdrop-blur-sm border border-accent-500/10 hover:border-accent-500/20
                         transition-all duration-300 group hover:shadow-subtle`}
                        >
                            <div className="flex items-center mb-4">
                                <div
                                    className="w-10 h-10 flex items-center justify-center rounded-lg 
                               bg-accent-500/10 group-hover:bg-accent-500/20 transition-colors"
                                >
                                    <i
                                        className={`fas ${feature.icon} text-xl text-white/70 group-hover:text-white/90 
                               transition-colors group-hover:scale-110 transform duration-300`}
                                    ></i>
                                </div>
                                <div className="flex-1 ml-4">
                                    <h3 className="text-lg font-bold text-white/90">
                                        {feature.title}
                                    </h3>
                                </div>
                            </div>
                            <p className="text-white/70 text-sm pl-14">
                                {feature.description}
                            </p>

                            <div className="mt-4 w-full h-1 bg-accent-500/10 rounded-full overflow-hidden">
                                <motion.div
                                    initial={{ width: 0 }}
                                    whileInView={{ width: "100%" }}
                                    viewport={{ once: true }}
                                    transition={{
                                        duration: 1,
                                        delay: index * 0.1,
                                    }}
                                    className="h-full bg-gradient-to-r from-accent-500/20 to-accent-600/20"
                                />
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Feature Summary */}
                <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    className="text-center mt-16 text-white/60 max-w-2xl mx-auto"
                >
                    <p className="text-sm">
                        All features are continuously updated and improved to
                        provide the best possible experience. Pro users get
                        access to enhanced capabilities and priority support.
                    </p>
                </motion.div>
            </div>
        </section>
    );
};

export default Features;
