import axios from "axios";
import "../Config.js";
import { cipher } from "../utils/Crypto.js";

const models = ["yanzgpt-revolution-25b-v3.5", "yanzgpt-legacy-72b-v3.5"];
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const base64Data = reader.result.split(",")[1]; // Ambil data tanpa header
            resolve(base64Data);
        };
        reader.onerror = (error) => reject(error);
    });

export const sendMessage = async (messages, images, isPro = false) => {
    try {
        const response = await axios(global.API_URL + "/chat", {
            headers: {
                authorization: `Bearer ${global.API_KEY}`,
                "content-type": "application/json",
            },
            data: {
                messages,
                images: images,
                model: models[isPro ? 1 : 0],
            },
            method: "POST",
        });

        return response.data.choices[0].message;
    } catch (error) {
        console.error(`YanzGPT Error: ${error}`);
        throw error;
    }
};

export const uploadImages = async (img) => {
    try {
        const imageBase64 = await toBase64(img);
        let fileName = new Date().getTime();
        const payload = {
            imageBase64: imageBase64,
            filename: fileName, // Nama file untuk disimpan di server
        };

        let response = await fetch(global.CDN_URL, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            throw new Error("Upload failed");
        }

        let data = await response.json();
        return data;
    } catch (error) {
        console.error("Error:", error);
        return null;
    }
};

export const checkPrompt = async (prompt) => {
    try {
        const response = await axios(global.API_URL + "/check_prompt", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                prompt,
            },
            method: "POST",
        });
        return response?.data;
    } catch (e) {
        console.error(`YanzGPT Error: ${e.response?.data || e.message}`);
        return { cmd: "default" };
    }
};

export const register = async (account) => {
    try {
        let dataToEnc = JSON.stringify({
            username: account.username,
            whatsapp: account.whatsapp,
            password: account.password,
        });

        let ciphertext = cipher(dataToEnc);

        if (!ciphertext) return false;

        let response = await axios(global.BACKEND + "/auth/register", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                ciphertext,
            },
            method: "POST",
        });
        return response?.data;
    } catch (e) {
        console.error("Auth Error: ", e);
    }
};

export const login = async (account) => {
    try {
        let dataToEnc = JSON.stringify({
            username: account.username,
            password: account.password,
        });
        let ciphertext = cipher(dataToEnc);

        if (!ciphertext) return false;
        let response = await axios(global.BACKEND + "/auth/login", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                ciphertext,
            },
            method: "POST",
        });
        return response?.data;
    } catch (e) {
        console.error("Auth Error: ", e);
    }
};

export const createNewChats = async (uid, token, title = "New Chats") => {
    try {
        let response = await axios(global.BACKEND + "/chat/new", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                uid: uid,
                token: token,
                title: title,
            },
            method: "POST",
        });

        return response?.data;
    } catch (e) {
        console.error("Error When Creating New Chats: ", e);
    }
};

export const pushChats = async (uid, chat_id, token, content) => {
    try {
        let response = await axios(global.BACKEND + "/chat/push", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                uid: uid,
                chat_id: chat_id,
                token: token,
                content: content,
            },
            method: "POST",
        });

        return response?.data;
    } catch (e) {
        console.error("Error When pushing Chats: ", e);
    }
};

export const deleteChats = async (uid, chat_id, token) => {
    try {
        let response = await axios(global.BACKEND + "/chat/delete", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                uid: uid,
                chat_id: chat_id,
                token: token,
            },
            method: "POST",
        });

        return response?.data;
    } catch (e) {
        console.error("Error When pushing Chats: ", e);
    }
};

export const getAllChats = async (uid, token) => {
    try {
        let response = await axios(global.BACKEND + "/chat/all", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                uid: uid,
                token: token,
            },
            method: "POST",
        });

        return response?.data;
    } catch (e) {
        console.error("Error When Creating New Chats: ", e);
    }
};

export const getAuthInfo = async (token) => {
    try {
        let response = await axios(global.BACKEND + "/auth/get_info", {
            headers: {
                "content-type": "application/json",
            },
            data: {
                token: token,
            },
            method: "POST",
        });

        return response?.data;
    } catch (e) {
        console.error("Error When Creating New Chats: ", e);
    }
};
