import CryptoJS from "crypto-js";

export const cipher = (data) => {
    const iv = CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex);
    const secretKey = CryptoJS.lib.WordArray.random(32).toString(
        CryptoJS.enc.Hex
    );

    const encrypted = CryptoJS.AES.encrypt(
        data,
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
        }
    );

    return `${iv}.${secretKey}.${encrypted.toString()}`;
};

export const decipher = (ciphertext) => {
    const [iv, secretKey, encryptedData] = ciphertext.split(".");

    const decrypted = CryptoJS.AES.decrypt(
        encryptedData,
        CryptoJS.enc.Hex.parse(secretKey),
        {
            iv: CryptoJS.enc.Hex.parse(iv),
        }
    );

    return decrypted.toString(CryptoJS.enc.Utf8);
};
