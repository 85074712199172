const combinedQuestions = [
    "🍕 Makanan favorit apa?",
    "🌴 Liburan impian dimana?",
    "🐶 Lebih suka kucing?",
    "📚 Buku favoritmu apa?",
    "🎬 Film terbaik kapan?",
    "🎨 Hobi favorit apa?",
    "🦸 Superpower yang dipilih?",
    "🎤 Lagu karaoke apa?",
    "💸 Uang sejuta buat?",
    "🧘 Cara santai favorit?",
    "⏳ Ingin pergi kemana?",
    "🎉 Hal paling seru?",
    "🎶 Musik favorit apa?",
    "🍂 Musim favorit apa?",
    "😨 Ketakutan terbesar apa?",
    "🎢 Pengalaman seru kapan?",
    "🚗 Mobil impian apa?",
    "💼 Pekerjaan ideal apa?",
    "🌎 Negara favorit apa?",
    "📱 Aplikasi terbaik apa?",
    "🏠 Rumah impian dimana?",
    "🎁 Hadiah terbaik apa?",
    "📅 Kenangan terindah kapan?",
    "🎮 Game favorit apa?",
    "👗 Pakaian favorit apa?",
    "🎨 Gambar kucing duduk",
    "🖼️ Gambar rumah klasik",
    "🏞️ Gambar pantai sunset",
    "🌄 Gambar gunung pagi",
    "🏙️ Gambar kota futuristik",
    "🚗 Gambar mobil sport",
    "🏠 Gambar rumah modern",
    "🐦 Gambar burung terbang",
    "🦁 Gambar singa mengaum",
    "🐲 Gambar naga merah",
    "🌸 Gambar bunga mekar",
    "🌌 Gambar langit malam",
    "🐘 Gambar gajah besar",
    "🚢 Gambar kapal laut",
    "🦋 Gambar kupu-kupu cantik",
    "📰 Berita politik terbaru",
    "📢 Berita teknologi terbaru",
    "🗞️ Berita bisnis terbaru",
    "🔍 Cari berita olahraga",
    "📉 Berita ekonomi global",
    "🖥️ Cari tren teknologi",
    "🌍 Berita lingkungan terkini",
    "📡 Berita teknologi AI",
    "🏛️ Cari berita hukum",
    "🏥 Berita kesehatan global",
    "💻 Berita startup terkini",
    "🚀 Berita luar angkasa",
    "🎓 Berita pendidikan terbaru",
    "🎭 Berita budaya internasional",
    "🌞 Cuaca hari ini",
    "🌧️ Cuaca besok bagaimana?",
    "🌪️ Cuaca minggu depan?",
    "❄️ Suhu esok hari",
    "🌤️ Perkiraan cuaca minggu",
    "⛈️ Ramalan cuaca hujan?",
    "☀️ Lihat suhu siang",
    "🌨️ Cuaca sore nanti",
    "🌬️ Kecepatan angin berapa?",
    "🌫️ Kabut di daerah?",
    "🌡️ Lihat suhu sekarang",
    "🌦️ Ramalan cuaca cerah?",
    "☁️ Cuaca mendung nanti",
    "🌩️ Lihat cuaca petir",
    "☔ Ramalan hujan besok",
    "🍕 Apa makan favorit?",
    "🌴 Keinginan liburan kemana?",
    "🐶 Lebih suka anjing?",
    "📚 Buku kesukaan apa?",
    "🎬 Film yang keren?",
    "🎨 Gambar laut biru",
    "🦸 Kekuatan super dipilih?",
    "🎤 Lagu favorit kamu?",
    "💸 Sejuta rupiah buat?",
    "🧘 Cara relax gimana?",
    "🎉 Hal paling gila?",
    "🎶 Genre musik suka?",
    "🎢 Naik roller coaster?",
    "⏳ Ingin ke masa?",
    "🚗 Mobil impian lamborghini?",
    "🏞️ Gambar hutan hujan",
    "📰 Cari berita kripto",
    "🖼️ Gambar pelangi indah",
    "🌄 Gambar matahari pagi",
    "🐘 Gambar binatang besar",
    "🎁 Hadiah ulang tahun?",
    "🎮 Game paling suka?",
    "👗 Pakaian favorit apa?",
    "🌧️ Cuaca mendung besok?",
    "🌬️ Angin di laut?",
    "🏠 Rumah impian besar?",
    "🌟 Berita artis trending?",
    "🚀 Teknologi luar angkasa?",
    "🎭 Budaya lokal unik?",
    "🐦 Gambar burung biru",
    "📅 Acara spesial kapan?",
    "🖥️ Inovasi teknologi baru?",
    "🎓 Berita pendidikan AI?",
    "🚢 Gambar kapal besar",
    "🦁 Gambar hewan buas",
    "🌸 Gambar bunga mawar",
    "🌌 Gambar bintang langit",
    "🏛️ Hukum terbaru penting?",
    "🏥 Berita medis inovatif?",
    "🎶 Musik klasik favorit?",
    "📉 Tren ekonomi dunia?",
    "💻 Berita startup teknologi",
    "🏞️ Pemandangan alam indah",
    "🌍 Isu lingkungan terbaru?",
    "📱 Aplikasi favorit kamu?",
    "🐱 Hewan peliharaan lucu?",
    "🚢 Gambar kapal tenggelam",
    "🌪️ Badai besar mendekat?",
    "🌤️ Langit cerah siang?",
    "⛈️ Hujan deras malam?",
    "☀️ Matahari terik hari?",
    "🌫️ Lihat kabut tebal",
    "🌡️ Suhu tinggi sekarang",
    "🌦️ Ramalan cuaca cerah?",
    "🌩️ Petir menyambar dimana?",
    "❄️ Cuaca dingin esok?",
    "💡 Explore digital cameras",
    "🌎 Play geography trivia",
    "🌍 Karaoke night ideas",
    "🏆 Explore digital cameras",
    "🍕 Best pizza recipe",
    "🍔 Plan the perfect week",
    "🧩 Explore digital cameras",
    "🌎 Imagine a flower dress",
    "🧩 What is Earth Day?",
    "💻 Play geography trivia",
    "🏆 Karaoke night ideas",
    "🎯 Learn to code",
    "💡 How computers work",
    "💡 How to beat Rubik's Cube",
    "💡 Plan the perfect week",
    "📅 Explore digital cameras",
    "💻 Explore digital cameras",
    "📚 Play geography trivia",
    "💻 Watch a classic movie",
    "🏆 Master a video game",
    "🧀 Plan the perfect week",
    "🎬 Karaoke night ideas",
    "🍔 Plan the perfect week",
    "🏆 Karaoke night ideas",
    "🌻 Karaoke night ideas",
    "💡 Karaoke night ideas",
    "🌻 How to beat Rubik's Cube",
    "📅 Imagine a flower dress",
    "🏆 Imagine a flower dress",
    "🎸 History of photography",
    "💻 Plan the perfect week",
    "🧩 Karaoke night ideas",
    "📷 What is Earth Day?",
    "🌍 Imagine a tasty burger",
    "💻 Plan the perfect week",
    "📷 Rock music trivia",
    "💡 Plan the perfect week",
    "🎯 Watch a classic movie",
    "🎤 Book reels",
    "📷 Learn about ancient scrolls",
    "🎮 Imagine a flower dress",
    "💻 How computers work",
    "🎮 What is Earth Day?",
    "🌻 Plan the perfect week",
    "💻 History of photography",
    "🧩 How computers work",
    "🧀 Learn to code",
    "🎸 Watch a classic movie",
    "🧩 Karaoke night ideas",
    "💡 What is Earth Day?",
    "🧩 How computers work",
    "🎤 Learn about ancient scrolls",
    "💻 How to beat Rubik's Cube",
    "🌎 World's stinkiest cheese",
    "🧩 How to win a trophy",
    "💻 Imagine a tasty burger",
    "🧀 Book reels",
    "🧩 How computers work",
    "🎮 Win at darts",
    "🌎 Explore digital cameras",
    "📅 Play geography trivia",
    "📖 Rock music trivia",
    "📅 History of photography",
    "🎸 Win at darts",
    "📷 World's stinkiest cheese",
    "📖 Win at darts",
    "🎮 Imagine a tasty burger",
    "📷 How to beat Rubik's Cube",
    "💡 How to beat Rubik's Cube",
    "💻 Learn to code",
    "🎤 How to beat Rubik's Cube",
    "🎮 Best pizza recipe",
    "📖 Play geography trivia",
    "📅 Master a video game",
    "🎯 Master a video game",
    "📚 World's stinkiest cheese",
    "📜 Watch a classic movie",
    "📷 World's stinkiest cheese",
    "💡 World's stinkiest cheese",
    "🍕 Learn to code",
    "🏆 Rock music trivia",
    "🎯 Book reels",
    "🌻 Win at darts",
    "🎯 What is Earth Day?",
    "🏆 Watch a classic movie",
    "💻 Explore digital cameras",
    "📖 What is Earth Day?",
    "🧀 Imagine a tasty burger",
    "🎮 Imagine a flower dress",
    "🍕 Explore digital cameras",
    "🍔 Learn about ancient scrolls",
    "💻 Master a video game",
    "🧩 Explore digital cameras",
    "📅 Watch a classic movie",
    "🎸 Win at darts",
    "🌍 Imagine a flower dress",
    "🎯 How computers work",
    "🌻 How to beat Rubik's Cube",
    "📜 How to beat Rubik's Cube",
    "🌍 Explore digital cameras",
    "🧩 Watch a classic movie",
    "📜 Play geography trivia",
    "📜 What is Earth Day?",
    "🧩 Win at darts",
    "📖 Watch a classic movie",
    "🎤 Book reels",
    "🧀 Imagine a tasty burger",
    "🍔 History of photography",
    "📜 Book reels",
    "🎯 Master a video game",
    "💡 What is Earth Day?",
    "💡 Imagine a tasty burger",
    "🌻 Karaoke night ideas",
    "🧩 Watch a classic movie",
    "💡 History of photography",
    "📚 How computers work",
    "🏆 What is Earth Day?",
    "💻 History of photography",
    "📅 Book reels",
    "📚 Watch a classic movie",
    "🎸 How computers work",
    "🎤 How computers work",
    "💻 How to win a trophy",
    "🍔 Explore digital cameras",
    "🍕 Karaoke night ideas",
    "💻 Book reels",
    "📖 Explore digital cameras",
    "📷 Best pizza recipe",
    "🍕 Master a video game",
    "📷 Book reels",
    "🏆 Win at darts",
    "🍕 What is Earth Day?",
    "🌍 Best pizza recipe",
    "🎮 Play geography trivia",
    "📖 Rock music trivia",
    "🎯 World's stinkiest cheese",
    "🌍 Win at darts",
    "🌍 Play geography trivia",
    "💻 Imagine a flower dress",
    "🍕 Imagine a tasty burger",
    "🎸 World's stinkiest cheese",
    "🎮 Win at darts",
    "🎮 How computers work",
    "🎤 Imagine a flower dress",
    "📷 How to win a trophy",
    "🧀 Karaoke night ideas",
    "📚 Win at darts",
    "💻 Watch a classic movie",
    "🌻 Imagine a flower dress",
    "🌎 Learn about ancient scrolls",
    "📚 Book reels",
    "🧩 Master a video game",
    "🌍 Book reels",
    "🏆 How to beat Rubik's Cube",
    "🎯 Plan the perfect week",
    "🎸 Learn to code",
    "📖 Imagine a flower dress",
    "🌎 How to win a trophy",
    "🌎 Learn to code",
    "🧩 Best pizza recipe",
    "🎬 History of photography",
    "💡 Book reels",
    "🧀 What is Earth Day?",
    "🧩 Win at darts",
    "📅 Explore digital cameras",
    "🎬 What is Earth Day?",
    "🧀 Imagine a flower dress",
    "🎮 Best pizza recipe",
    "🌻 World's stinkiest cheese",
    "🎸 How computers work",
    "🎯 Master a video game",
    "🧀 World's stinkiest cheese",
    "🎤 How to beat Rubik's Cube",
    "🎯 Plan the perfect week",
    "🎤 Play geography trivia",
    "🎯 Karaoke night ideas",
    "📅 How to beat Rubik's Cube",
    "📅 Karaoke night ideas",
    "📖 How to beat Rubik's Cube",
    "🎬 Learn about ancient scrolls",
    "🧩 Win at darts",
    "🍕 Learn about ancient scrolls",
    "📅 Learn to code",
    "🏆 World's stinkiest cheese",
    "📚 Rock music trivia",
    "🧀 Learn about ancient scrolls",
    "📜 World's stinkiest cheese",
    "🎬 Karaoke night ideas",
    "🍔 Learn to code",
    "🌎 How to beat Rubik's Cube",
    "📅 Watch a classic movie",
    "🍕 Win at darts",
    "📖 Plan the perfect week",
    "🍕 Karaoke night ideas",
    "📅 Imagine a flower dress",
    "🧩 History of photography",
    "📷 Plan the perfect week",
    "🎤 Explore digital cameras",
    "🎯 Win at darts",
    "🎸 What is Earth Day?",
    "🧩 How computers work",
    "🧩 Best pizza recipe",
    "🎯 Imagine a tasty burger",
    "💻 Rock music trivia",
    "🍔 Best pizza recipe",
    "🍔 How computers work",
    "🎯 Karaoke night ideas",
    "🎸 Learn to code",
    "💻 Plan the perfect week",
    "📅 Watch a classic movie",
    "📜 Imagine a tasty burger",
    "🎸 Book reels",
    "🎮 What is Earth Day?",
    "🧩 History of photography",
    "🌻 History of photography",
    "📚 Learn to code",
    "📷 Imagine a tasty burger",
    "💻 Master a video game",
    "🎤 History of photography",
    "🌍 History of photography",
    "🎬 Play geography trivia",
    "🍕 Master a video game",
    "🌻 Imagine a tasty burger",
    "📜 Play geography trivia",
    "🎯 Play geography trivia",
    "🏆 Play geography trivia",
    "🎮 Imagine a flower dress",
    "🌍 Plan the perfect week",
    "🍕 Best pizza recipe",
    "🍕 How computers work",
    "🎤 How to beat Rubik's Cube",
    "🎮 Rock music trivia",
    "📅 Karaoke night ideas",
    "🧩 Best pizza recipe",
    "🌻 Imagine a flower dress",
    "🧀 Play geography trivia",
    "🍔 Rock music trivia",
    "🎸 Explore digital cameras",
    "🎯 Imagine a flower dress",
    "🎤 World's stinkiest cheese",
    "🍔 Watch a classic movie",
    "🎬 How to beat Rubik's Cube",
    "🏆 What is Earth Day?",
    "🎬 What is Earth Day?",
    "📅 History of photography",
    "🏆 Best pizza recipe",
    "📚 Learn to code",
    "🌎 Karaoke night ideas",
    "🎸 Watch a classic movie",
    "🍔 Explore digital cameras",
    "🍔 Rock music trivia",
    "🌻 Imagine a flower dress",
    "📜 Learn to code",
    "📚 Learn about ancient scrolls",
    "🎮 How to win a trophy",
    "🎯 Play geography trivia",
    "🎯 Best pizza recipe",
    "📖 History of photography",
    "🎬 Play geography trivia",
    "🍔 Learn about ancient scrolls",
    "🧩 Master a video game",
    "🍔 What is Earth Day?",
    "🍕 Rock music trivia",
    "📖 What is Earth Day?",
    "🍔 Imagine a flower dress",
    "📅 Learn to code",
    "🍕 Karaoke night ideas",
    "🧀 Karaoke night ideas",
    "📷 Win at darts",
    "🍕 Explore digital cameras",
    "🌍 Win at darts",
    "🎯 Master a video game",
    "🍔 Learn to code",
    "🎸 Watch a classic movie",
    "🍕 Book reels",
    "📜 Karaoke night ideas",
    "💻 How to win a trophy",
    "📚 Karaoke night ideas",
    "💻 Play geography trivia",
    "🎤 What is Earth Day?",
    "🌍 Learn about ancient scrolls",
    "📷 Master a video game",
    "🌍 World's stinkiest cheese",
    "📖 Watch a classic movie",
    "🎮 How to win a trophy",
    "🎤 Book reels",
    "🧀 Imagine a flower dress",
    "🍔 Learn to code",
    "📖 Win at darts",
    "📷 World's stinkiest cheese",
    "🎮 History of photography",
    "🎯 Rock music trivia",
    "🍕 Master a video game",
    "🌻 Rock music trivia",
    "🎸 How to win a trophy",
    "🧀 Master a video game",
    "📚 How to beat Rubik's Cube",
    "🧩 Plan the perfect week",
    "🌻 Best pizza recipe",
    "💡 Win at darts",
    "📜 What is Earth Day?",
    "💻 Master a video game",
    "📖 Learn to code",
    "🍔 Watch a classic movie",
    "🎯 Learn about ancient scrolls",
    "🧩 Rock music trivia",
    "🌎 Win at darts",
    "📷 How to beat Rubik's Cube",
    "🍕 How to beat Rubik's Cube",
    "🌎 Best pizza recipe",
    "🎸 What is Earth Day?",
    "🎯 World's stinkiest cheese",
    "🌍 Best pizza recipe",
    "🎯 Rock music trivia",
    "🧀 Play geography trivia",
    "🧀 Plan the perfect week",
    "📖 Rock music trivia",
    "🎯 History of photography",
    "🎬 Win at darts",
    "💡 How to win a trophy",
    "🌻 Plan the perfect week",
    "🌍 Explore digital cameras",
    "📅 What is Earth Day?",
    "📖 Plan the perfect week",
    "🧩 World's stinkiest cheese",
    "🌍 World's stinkiest cheese",
    "📚 Best pizza recipe",
    "🏆 How computers work",
    "🧀 Imagine a flower dress",
    "🌎 Rock music trivia",
    "📜 Book reels",
    "💡 How computers work",
    "📚 Master a video game",
    "💻 What is Earth Day?",
    "🎸 How computers work",
    "🏆 Explore digital cameras",
    "🍕 Learn about ancient scrolls",
    "📚 Rock music trivia",
    "🌻 Master a video game",
    "🌍 Best pizza recipe",
    "🧩 Explore digital cameras",
    "🎸 Plan the perfect week",
    "🌍 Imagine a flower dress",
    "🍔 How to beat Rubik's Cube",
    "🌍 How computers work",
    "🍕 Best pizza recipe",
    "🍔 Rock music trivia",
    "📷 How to win a trophy",
    "📖 Best pizza recipe",
    "🏆 Imagine a tasty burger",
    "🌍 Win at darts",
    "📜 Learn about ancient scrolls",
    "🧀 Plan the perfect week",
    "📜 History of photography",
    "🎤 Plan the perfect week",
    "🎸 Rock music trivia",
    "📖 History of photography",
    "🎮 Best pizza recipe",
    "🌎 Learn to code",
    "🌻 World's stinkiest cheese",
    "🎮 Play geography trivia",
    "📚 Learn about ancient scrolls",
    "🎬 How to win a trophy",
    "🎬 Imagine a tasty burger",
    "🎬 How computers work",
    "📚 Best pizza recipe",
    "🎤 Book reels",
    "🌻 Best pizza recipe",
    "📷 How to beat Rubik's Cube",
    "📷 Book reels",
    "🏆 Imagine a flower dress",
    "🎬 Win at darts",
    "📖 How to win a trophy",
    "📖 Play geography trivia",
    "🧀 Karaoke night ideas",
    "📚 Imagine a tasty burger",
    "🧀 How computers work",
    "🌍 Book reels",
    "📖 Best pizza recipe",
    "🎤 What is Earth Day?",
    "📅 What is Earth Day?",
    "🌻 What is Earth Day?",
    "🍕 Play geography trivia",
    "🧀 History of photography",
    "🎬 Rock music trivia",
    "🍔 History of photography",
    "🎤 Karaoke night ideas",
    "🍕 World's stinkiest cheese",
    "📜 Explore digital cameras",
    "📷 Play geography trivia",
    "📖 Karaoke night ideas",
    "🌎 Plan the perfect week",
    "🎬 Play geography trivia",
    "💻 Karaoke night ideas",
    "📚 How to beat Rubik's Cube",
    "🧩 Imagine a flower dress",
    "📅 How computers work",
    "🌻 Explore digital cameras",
    "💡 Learn to code",
    "📷 Play geography trivia",
    "📅 Learn to code",
    "📅 Play geography trivia",
    "📜 What is Earth Day?",
    "🍔 Win at darts",
    "🎤 Imagine a flower dress",
    "🍕 How to win a trophy",
    "🧀 Play geography trivia",
    "🍔 Plan the perfect week",
    "🏆 History of photography",
    "📷 Win at darts",
    "💡 Karaoke night ideas",
    "🏆 Best pizza recipe",
    "📷 Karaoke night ideas",
    "💡 Learn to code",
    "🎸 How to win a trophy",
    "🎸 Watch a classic movie",
    "🎮 Imagine a tasty burger",
    "🍕 Karaoke night ideas",
    "💻 Master a video game",
    "🎬 Win at darts",
    "🎤 Imagine a tasty burger",
    "🌻 Master a video game",
    "📖 What is Earth Day?",
    "🌍 Watch a classic movie",
    "📜 World's stinkiest cheese",
    "📜 How computers work",
    "📖 Karaoke night ideas",
    "🎸 Imagine a tasty burger",
    "💻 Book reels",
    "🌻 How computers work",
    "📚 How to win a trophy",
    "🧀 Imagine a flower dress",
    "🏆 How to win a trophy",
    "🎮 Play geography trivia",
    "💻 Learn to code",
    "📜 World's stinkiest cheese",
    "🎯 Book reels",
    "🎬 How computers work",
    "📷 Watch a classic movie",
    "📖 How to beat Rubik's Cube",
    "📅 What is Earth Day?",
    "📚 Imagine a flower dress",
    "📜 How to beat Rubik's Cube",
    "📅 What is Earth Day?",
    "🌍 How to beat Rubik's Cube",
    "🌍 Learn about ancient scrolls",
    "🧩 Best pizza recipe",
    "🌻 Watch a classic movie",
    "🌍 Book reels",
    "🌍 Karaoke night ideas",
    "🎸 Imagine a tasty burger",
    "🌍 Rock music trivia",
    "💻 History of photography",
    "🌍 Plan the perfect week",
    "🌻 Best pizza recipe",
    "🎮 Watch a classic movie",
    "🧩 Rock music trivia",
    "🎮 World's stinkiest cheese",
    "🌍 History of photography",
    "💻 How to win a trophy",
    "🎯 Imagine a tasty burger",
    "🎬 Karaoke night ideas",
    "📜 Win at darts",
    "🎬 Best pizza recipe",
    "💡 Win at darts",
    "🏆 Play geography trivia",
    "📅 Play geography trivia",
    "🎬 How to win a trophy",
    "🌻 Win at darts",
    "🎯 Learn to code",
    "📖 How computers work",
    "📜 Explore digital cameras",
    "📷 Plan the perfect week",
    "📅 Learn to code",
    "🍔 Imagine a tasty burger",
    "📷 What is Earth Day?",
    "🧀 History of photography",
    "🎮 Book reels",
    "🧀 Learn to code",
    "🌻 Imagine a flower dress",
    "🎬 Book reels",
    "💡 History of photography",
    "🎤 Win at darts",
    "🏆 How computers work",
    "🎮 How to beat Rubik's Cube",
    "🎯 World's stinkiest cheese",
    "💡 Watch a classic movie",
    "📖 Learn about ancient scrolls",
    "🏆 Learn to code",
    "📖 Plan the perfect week",
    "📷 Karaoke night ideas",
    "🌎 Play geography trivia",
    "📷 What is Earth Day?",
    "📖 What is Earth Day?",
    "📷 Book reels",
    "🎤 Explore digital cameras",
    "🧩 Imagine a tasty burger",
    "🌻 Karaoke night ideas",
    "💡 How to win a trophy",
    "🏆 Watch a classic movie",
    "📜 What is Earth Day?",
    "📅 What is Earth Day?",
    "🏆 Master a video game",
    "📜 History of photography",
    "🍔 How computers work",
    "🍔 Learn about ancient scrolls",
    "📷 Karaoke night ideas",
    "🌍 History of photography",
    "🏆 World's stinkiest cheese",
    "🎯 History of photography",
    "🎬 How computers work",
    "🌍 Rock music trivia",
    "🎸 How computers work",
    "🎯 What is Earth Day?",
    "🎸 Imagine a flower dress",
    "📜 Plan the perfect week",
    "💡 Watch a classic movie",
    "🌻 Imagine a tasty burger",
    "🌎 Imagine a tasty burger",
    "🌍 Play geography trivia",
    "📖 What is Earth Day?",
    "🌎 How to win a trophy",
    "🎮 Best pizza recipe",
    "📅 Explore digital cameras",
    "🌎 Win at darts",
    "📅 Imagine a tasty burger",
    "💻 Best pizza recipe",
    "🎤 Book reels",
    "💻 Plan the perfect week",
    "🌍 What is Earth Day?",
    "📚 Learn to code",
    "🌍 Learn to code",
    "📜 World's stinkiest cheese",
    "🌎 How computers work",
    "🎯 Imagine a flower dress",
    "🌻 What is Earth Day?",
    "🌎 Learn to code",
    "🎸 What is Earth Day?",
    "🎯 Best pizza recipe",
    "🎸 Play geography trivia",
    "🌎 How to beat Rubik's Cube",
    "🎯 What is Earth Day?",
    "🌍 Imagine a tasty burger",
    "🎮 Imagine a flower dress",
    "🎯 Learn to code",
    "📅 Explore digital cameras",
    "🍔 Imagine a flower dress",
    "🎸 Karaoke night ideas",
    "🌻 History of photography",
    "🧀 Watch a classic movie",
    "🧀 Win at darts",
    "💡 Book reels",
    "🌻 Imagine a tasty burger",
    "📜 Master a video game",
    "🎯 Learn about ancient scrolls",
    "🧩 Karaoke night ideas",
    "🎮 How computers work",
    "📅 Win at darts",
    "🎸 What is Earth Day?",
    "🎮 Master a video game",
    "📜 How computers work",
    "📅 Learn to code",
    "🎸 Imagine a flower dress",
    "🎬 Karaoke night ideas",
    "🧩 Imagine a tasty burger",
    "🎯 What is Earth Day?",
    "📜 Rock music trivia",
    "🎬 Best pizza recipe",
    "🎬 Plan the perfect week",
    "💡 What is Earth Day?",
    "🍔 What is Earth Day?",
    "📅 History of photography",
    "📜 Play geography trivia",
    "📖 Book reels",
    "🧩 Imagine a tasty burger",
    "🧩 Plan the perfect week",
    "📅 Learn about ancient scrolls",
    "🧩 Rock music trivia",
    "🍔 Explore digital cameras",
    "🍔 Imagine a flower dress",
    "📚 Plan the perfect week",
    "🌎 Play geography trivia",
    "📜 Best pizza recipe",
    "🏆 Imagine a flower dress",
    "🎮 World's stinkiest cheese",
    "📚 Watch a classic movie",
    "🎯 What is Earth Day?",
    "📷 Master a video game",
    "📖 Play geography trivia",
    "📖 Learn about ancient scrolls",
    "💡 How to beat Rubik's Cube",
    "🌎 How computers work",
    "📚 World's stinkiest cheese",
    "🎬 Rock music trivia",
    "📜 Play geography trivia",
    "🎸 What is Earth Day?",
    "🧀 What is Earth Day?",
    "💻 World's stinkiest cheese",
    "🍕 Rock music trivia",
    "💻 Watch a classic movie",
    "📷 Learn to code",
    "🧀 Win at darts",
    "📖 How computers work",
    "💻 Rock music trivia",
    "📖 Best pizza recipe",
    "📖 History of photography",
    "💻 Best pizza recipe",
    "💻 Learn to code",
    "💻 Explore digital cameras",
    "📖 Learn to code",
    "💻 Imagine a flower dress",
    "📅 How computers work",
    "🌻 Play geography trivia",
    "🎮 Win at darts",
    "🎮 History of photography",
    "📅 History of photography",
    "🎬 Book reels",
    "💡 Imagine a tasty burger",
    "🌎 Learn to code",
    "💻 Watch a classic movie",
    "💡 World's stinkiest cheese",
    "🎤 Master a video game",
    "🎯 Imagine a tasty burger",
    "💡 Rock music trivia",
    "🎸 World's stinkiest cheese",
    "🏆 What is Earth Day?",
    "🌎 How to win a trophy",
    "🌎 Plan the perfect week",
    "💡 Learn about ancient scrolls",
    "🌎 How to win a trophy",
    "🧀 Imagine a flower dress",
    "📖 Imagine a tasty burger",
    "🎮 World's stinkiest cheese",
    "🏆 How to beat Rubik's Cube",
    "🌻 Book reels",
    "🍔 World's stinkiest cheese",
    "🍕 Learn about ancient scrolls",
    "🎮 Imagine a flower dress",
    "📜 How to beat Rubik's Cube",
    "🎬 Rock music trivia",
    "📜 Plan the perfect week",
    "💻 Best pizza recipe",
    "📷 World's stinkiest cheese",
    "🧀 Explore digital cameras",
    "🌻 Watch a classic movie",
    "🎤 How computers work",
    "📜 Learn to code",
    "📅 Rock music trivia",
    "📅 Watch a classic movie",
    "🍕 Book reels",
    "🎤 Imagine a flower dress",
    "📅 Learn about ancient scrolls",
    "📅 What is Earth Day?",
    "📅 Imagine a flower dress",
    "🌻 How to beat Rubik's Cube",
    "💡 Imagine a tasty burger",
    "🍔 World's stinkiest cheese",
    "📷 Learn to code",
    "🧀 Book reels",
    "🎤 Master a video game",
    "📜 Karaoke night ideas",
    "📚 Play geography trivia",
    "📖 World's stinkiest cheese",
    "📷 Explore digital cameras",
    "🏆 Learn to code",
    "🍔 Karaoke night ideas",
    "🎬 History of photography",
    "📚 Win at darts",
    "🎯 Play geography trivia",
    "💻 How to beat Rubik's Cube",
    "📷 Watch a classic movie",
    "💡 World's stinkiest cheese",
    "🎸 Book reels",
    "💻 History of photography",
    "📅 Book reels",
    "🍔 What is Earth Day?",
    "💡 Imagine a flower dress",
    "💡 What is Earth Day?",
    "🧀 Win at darts",
    "📷 How to beat Rubik's Cube",
    "🌍 Rock music trivia",
    "💻 Win at darts",
    "💡 Imagine a tasty burger",
    "🌍 Learn about ancient scrolls",
    "🌻 History of photography",
    "🍔 Explore digital cameras",
    "🌍 How computers work",
    "💡 Learn to code",
    "💡 Play geography trivia",
    "🏆 Explore digital cameras",
    "🎤 Learn about ancient scrolls",
    "🎸 Master a video game",
    "🏆 Learn to code",
    "🍔 How to win a trophy",
    "📚 Master a video game",
    "🎸 World's stinkiest cheese",
    "🎮 History of photography",
    "🎮 Best pizza recipe",
    "📜 Imagine a flower dress",
    "🌻 Watch a classic movie",
    "💻 History of photography",
    "🌍 Watch a classic movie",
    "🎯 Imagine a flower dress",
    "🎯 Best pizza recipe",
    "🌎 How computers work",
    "📚 Explore digital cameras",
    "🎯 Master a video game",
    "📖 How to beat Rubik's Cube",
    "🌻 Karaoke night ideas",
    "🎮 Plan the perfect week",
    "📷 Win at darts",
    "🌎 Play geography trivia",
    "🎤 Master a video game",
    "💡 Plan the perfect week",
    "🎯 Play geography trivia",
    "🧩 Explore digital cameras",
    "🍕 Book reels",
    "💻 Book reels",
    "📚 Learn about ancient scrolls",
    "💻 How computers work",
    "🍕 Imagine a flower dress",
    "🎯 History of photography",
    "🌍 Win at darts",
    "🎬 Karaoke night ideas",
    "📚 Win at darts",
    "🍔 Explore digital cameras",
    "🏆 What is Earth Day?",
    "🎬 How to win a trophy",
    "📜 World's stinkiest cheese",
    "🎸 How computers work",
    "🧀 Book reels",
    "🎤 Plan the perfect week",
    "💻 How to beat Rubik's Cube",
    "💻 Imagine a tasty burger",
    "🌎 World's stinkiest cheese",
    "🧀 Learn about ancient scrolls",
    "📖 What is Earth Day?",
    "🎤 Imagine a tasty burger",
    "🎤 Rock music trivia",
    "🧀 Learn about ancient scrolls",
    "🌎 Play geography trivia",
    "🎮 Watch a classic movie",
    "📚 Imagine a tasty burger",
    "🌍 Master a video game",
    "🎮 Book reels",
    "💻 Master a video game",
    "📖 Learn about ancient scrolls",
    "🎮 Explore digital cameras",
    "🍕 Explore digital cameras",
    "📜 Rock music trivia",
    "💻 History of photography",
    "🏆 Learn to code",
    "🍔 Play geography trivia",
    "🌻 Win at darts",
    "🧀 Watch a classic movie",
    "🧀 Win at darts",
    "🧀 What is Earth Day?",
    "💻 What is Earth Day?",
    "🧀 Plan the perfect week",
    "🌻 Play geography trivia",
    "💻 Learn about ancient scrolls",
    "🌍 How to beat Rubik's Cube",
    "🏆 Learn about ancient scrolls",
    "💡 History of photography",
    "📚 Rock music trivia",
    "📜 What is Earth Day?",
    "🌍 What is Earth Day?",
    "📷 Imagine a tasty burger",
    "🎸 Win at darts",
    "🎮 How computers work",
    "💡 Best pizza recipe",
    "🎸 Karaoke night ideas",
    "🌍 Win at darts",
    "📜 Best pizza recipe",
    "💻 Rock music trivia",
    "🌍 Imagine a flower dress",
    "🎬 How computers work",
    "💻 Imagine a flower dress",
    "🎤 How computers work",
    "🌎 Imagine a tasty burger",
    "📜 Imagine a tasty burger",
    "📅 Master a video game",
    "🧩 Rock music trivia",
    "💡 Best pizza recipe",
    "📜 What is Earth Day?",
    "📅 How to beat Rubik's Cube",
    "🍕 Learn about ancient scrolls",
    "🧩 Win at darts",
    "📚 Rock music trivia",
    "💡 Karaoke night ideas",
    "📷 Watch a classic movie",
    "🍕 Imagine a flower dress",
    "📷 Rock music trivia",
    "🏆 How computers work",
    "🏆 Learn to code",
    "🌻 What is Earth Day?",
    "📚 Watch a classic movie",
    "📷 How to win a trophy",
    "📖 Book reels",
    "🎸 Plan the perfect week",
    "🎮 Karaoke night ideas",
    "🧩 Karaoke night ideas",
    "🌻 Best pizza recipe",
    "🍔 Imagine a flower dress",
    "🎯 Learn to code",
    "🎸 Best pizza recipe",
    "🌎 Master a video game",
    "📜 Best pizza recipe",
    "🌍 Play geography trivia",
    "🍕 Play geography trivia",
    "🍔 Best pizza recipe",
    "🌎 Win at darts",
    "🧀 Win at darts",
    "🌎 World's stinkiest cheese",
    "📖 How to beat Rubik's Cube",
    "🏆 Master a video game",
    "🎸 Master a video game",
    "🌎 Play geography trivia",
    "📜 Watch a classic movie",
    "📚 How to beat Rubik's Cube",
    "🎯 Best pizza recipe",
    "🌎 World's stinkiest cheese",
    "🏆 Book reels",
    "📜 Master a video game",
    "🌎 What is Earth Day?",
    "🎸 Learn to code",
    "🎯 Learn to code",
    "🍕 Imagine a flower dress",
    "🎮 Karaoke night ideas",
    "🧩 Imagine a tasty burger",
    "📚 Watch a classic movie",
    "🎤 Karaoke night ideas",
    "🏆 Explore digital cameras",
    "🍕 Learn about ancient scrolls",
    "🎯 Best pizza recipe",
    "🌎 Imagine a flower dress",
    "🧩 Imagine a tasty burger",
    "🎤 Rock music trivia",
    "🏆 Play geography trivia",
    "📚 Play geography trivia",
    "🏆 What is Earth Day?",
    "🏆 Learn to code",
    "🏆 Book reels",
    "🎤 Learn to code",
    "🧩 How to beat Rubik's Cube",
    "🍕 Explore digital cameras",
    "🌎 Best pizza recipe",
    "🍕 History of photography",
    "🍕 Explore digital cameras",
    "💻 Learn to code",
    "🎯 Learn about ancient scrolls",
    "🌎 Best pizza recipe",
    "📅 Play geography trivia",
    "🌎 Explore digital cameras",
    "🌻 Rock music trivia",
    "🎯 History of photography",
    "📅 Win at darts",
    "🎸 How computers work",
    "🎯 Book reels",
    "📖 World's stinkiest cheese",
    "🍕 Learn about ancient scrolls",
    "🧩 How to win a trophy",
    "🌎 Rock music trivia",
    "📷 What is Earth Day?",
    "🎯 Rock music trivia",
    "📖 Karaoke night ideas",
    "📚 Plan the perfect week",
    "🧀 Rock music trivia",
    "🍕 History of photography",
    "📅 Learn about ancient scrolls",
    "🎬 How to win a trophy",
    "🍔 What is Earth Day?",
    "📅 Plan the perfect week",
    "🎮 Learn about ancient scrolls",
    "🌎 Learn to code",
    "🌎 Plan the perfect week",
    "🧀 Imagine a flower dress",
    "🌍 Learn to code",
    "📚 Imagine a flower dress",
    "📅 Best pizza recipe",
    "🎤 Learn to code",
    "💻 Rock music trivia",
    "🧩 Win at darts",
    "🎬 Plan the perfect week",
    "🍕 Plan the perfect week",
    "🧩 Play geography trivia",
    "🍕 Win at darts",
    "📖 How to beat Rubik's Cube",
    "🧩 Best pizza recipe",
    "🌻 Play geography trivia",
    "🍕 How to win a trophy",
    "💡 What is Earth Day?",
    "🧀 Karaoke night ideas",
    "🌍 Explore digital cameras",
    "🧀 Learn about ancient scrolls",
    "🧩 World's stinkiest cheese",
    "🎬 Learn about ancient scrolls",
    "📷 Book reels",
    "🧩 Learn about ancient scrolls",
    "🎬 Plan the perfect week",
    "🌎 Plan the perfect week",
    "🎬 Best pizza recipe",
    "📅 Book reels",
    "🎸 Karaoke night ideas",
    "📅 Imagine a tasty burger",
    "💡 Imagine a flower dress",
    "🌎 Imagine a tasty burger",
    "🍕 World's stinkiest cheese",
    "📷 Win at darts",
    "🌍 How to win a trophy",
    "📜 History of photography",
    "🎯 Master a video game",
    "🌎 Learn about ancient scrolls",
    "📜 Watch a classic movie",
    "🍕 Learn to code",
    "📚 Plan the perfect week",
    "🧩 Plan the perfect week",
    "🎬 History of photography",
    "🌎 Learn about ancient scrolls",
    "🎮 Learn about ancient scrolls",
    "🍔 Imagine a flower dress",
    "🌍 What is Earth Day?",
    "🎤 How to beat Rubik's Cube",
    "🧩 What is Earth Day?",
    "💻 Master a video game",
    "📷 Master a video game",
    "📜 Win at darts",
    "💻 How to win a trophy",
    "📖 Win at darts",
    "💻 Book reels",
    "💻 Imagine a tasty burger",
    "📜 Rock music trivia",
    "🎤 Karaoke night ideas",
    "🧩 Win at darts",
    "📚 History of photography",
    "📖 Book reels",
    "🎮 How to win a trophy",
    "📷 What is Earth Day?",
    "📅 Best pizza recipe",
    "🍔 Karaoke night ideas",
    "🍕 How computers work",
    "📖 History of photography",
    "🎬 Imagine a flower dress",
    "💡 Imagine a flower dress",
    "🎬 Learn to code",
    "📅 Best pizza recipe",
    "🌎 Learn about ancient scrolls",
    "🎸 How to win a trophy",
    "🧩 Best pizza recipe",
    "🌻 History of photography",
    "🌻 How to win a trophy",
    "📚 World's stinkiest cheese",
    "💡 Book reels",
    "🎮 Master a video game",
    "🎬 Best pizza recipe",
    "🎸 Plan the perfect week",
    "🎤 Explore digital cameras",
    "🍕 What is Earth Day?",
    "🎮 Karaoke night ideas",
    "🌎 What is Earth Day?",
    "🌻 What is Earth Day?",
    "🍔 Book reels",
    "🍔 Imagine a tasty burger",
    "🏆 Karaoke night ideas",
    "🌎 World's stinkiest cheese",
    "🎤 Imagine a flower dress",
    "📷 How computers work",
    "🎤 How computers work",
    "📅 Explore digital cameras",
    "🌻 World's stinkiest cheese",
    "🎯 Plan the perfect week",
    "💡 Plan the perfect week",
    "🎮 How to win a trophy",
    "🍕 History of photography",
    "📖 Plan the perfect week",
    "🏆 Learn to code",
    "📜 How computers work",
    "🧩 Karaoke night ideas",
    "🎯 Imagine a tasty burger",
    "🎸 Learn to code",
    "📚 History of photography",
    "💻 How computers work",
    "🌎 How computers work",
    "📷 How to beat Rubik's Cube",
    "🍕 Imagine a tasty burger",
    "🎤 How computers work",
    "🌍 Rock music trivia",
    "🎤 Explore digital cameras",
    "🎬 Win at darts",
    "🎤 Plan the perfect week",
    "🎯 World's stinkiest cheese",
    "💻 Win at darts",
    "🎤 Master a video game",
    "🎸 Learn to code",
    "🎯 Plan the perfect week",
    "🎬 Watch a classic movie",
    "📜 How to win a trophy",
    "🎤 World's stinkiest cheese",
    "🍕 Imagine a flower dress",
];

export default combinedQuestions;
