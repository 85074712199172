import Cookies from "js-cookie";

export const setAuthCookie = (token) => {
	Cookies.set("auth", token, { expires: 7 });
};

export const getAuthCookie = () => {
	return Cookies.get("auth");
};

export const removeAuthCookie = () => {
	Cookies.remove("auth");
};
