import "../../styles/SkeletonLoader.css";
import { useState } from "react";

export const SkeletonLoader = () => {
    return (
        <div className="skeleton-loader">
            <div className="skeleton-text"></div>
            <div className="skeleton-text mid"></div>
            <div className="skeleton-text short"></div>
        </div>
    );
};

export const ImageLoader = ({ loadingType, imageArray }) => {
    const loaderIndicator = process.env.PUBLIC_URL + "/image_process.gif";
    return (
        <div className="image-container">
            {loadingType === "single" ? (
                <div className="loader">
                    <img
                        className="indicator"
                        src={loaderIndicator}
                        alt="loader"
                    />
                </div>
            ) : (
                <>
                    <div className="loader">
                        <img
                            className="indicator"
                            src={loaderIndicator}
                            alt="loader"
                        />
                    </div>
                    <div className="loader">
                        <img
                            className="indicator"
                            src={loaderIndicator}
                            alt="loader"
                        />
                    </div>
                    <div className="loader">
                        <img
                            className="indicator"
                            src={loaderIndicator}
                            alt="loader"
                        />
                    </div>
                    <div className="loader">
                        <img
                            className="indicator"
                            src={loaderIndicator}
                            alt="loader"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export const ImageGenerationLoader = ({ loadingType, image }) => {
    const [imageLoaded, setImageLoaded] = useState([]);

    const handleImageLoad = (index) => {
        setImageLoaded((prev) => {
            const updated = [...prev];
            updated[index] = true;
            return updated;
        });
    };

    const loaderIndicator = process.env.PUBLIC_URL + "/image_process.gif";

    return (
        <div className="image-container">
            {image &&
                (loadingType === "single" ? (
                    <>
                        {!imageLoaded[0] && (
                            <div className="loader">
                                <img
                                    className="indicator"
                                    src={loaderIndicator}
                                    alt="loader"
                                />
                            </div>
                        )}
                        <img
                            className={`img-content ${!imageLoaded[0] ? "hidden" : ""}`}
                            src={image}
                            alt="1"
                            onLoad={() => handleImageLoad(0)}
                            onError={() => alert("Unable to process image")}
                        />
                    </>
                ) : (
                    image.length > 0 &&
                    image.map((link, index) => (
                        <div className="result-ctr" key={index}>
                            {!imageLoaded[index] && (
                                <div className="loader">
                                    <img
                                        className="indicator"
                                        src={loaderIndicator}
                                        alt="loader"
                                    />
                                </div>
                            )}
                            <img
                                className={`img-content ${!imageLoaded[index] ? "hidden" : ""}`}
                                src={link}
                                alt={index}
                                onLoad={() => handleImageLoad(index)}
                                onError={() => alert("Unable to process image")}
                            />
                        </div>
                    ))
                ))}
        </div>
    );
};

export const DefaultLoader = () => {
    return (
        <div className="default-ctr">
            <div className="default-loader"></div>
        </div>
    );
};
