import { useState } from "react";
import { useNavigate } from "react-router-dom";

import "../../styles/ChatHeader.css";
import { Modal } from "./Modal";

const NewChatBtn = () => {
    const navigate = useNavigate();
    return (
        <button
            type="button"
            onClick={() => navigate(0)}
            className="flex justify-center items-center w-8 h-8 text-lg p-1.5 rounded hover:bg-[#131a2f] focus:outline-none focus:ring-1 text-gray-400 hover:bg-[#131a2f] focus:ring-[#17243a]"
            aria-expanded="false"
        >
            <i className="fa-regular fa-pen-to-square"></i>
        </button>
    );
};

export const ChatHeader = ({ sidebarOpen, setSidebarOpen, isAuth }) => {
    const navigate = useNavigate();
    const [userMenuOpen, setUserMenuOpen] = useState(false);
    const [temporaryChat, setTemporaryChat] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    const handleTemporary = () => {
        setTemporaryChat(!temporaryChat);
    };

    let userMenu = [
        {
            title: "Obrolan sementara",
            icon: "fa-solid fa-comment-slash",
            onClick: () => handleTemporary(),
        },
        {
            title: "Pengaturan",
            icon: "fa-solid fa-gear",
            onClick: () => alert("fitur ini belum tersedia"),
        },
    ];

    return (
        <>
            <nav className="fixed top-0 z-50 w-full border-b border-[#17243a] bg-[#0f0d1f] lg:bg-[#17243a]">
                <div className="px-3 py-3 lg:px-5 lg:pl-3">
                    <div className="flex items-center justify-between">
                        <div className="flex items-center justify-start">
                            {isAuth ? (
                                <button
                                    data-drawer-target="logo-sidebar"
                                    data-drawer-toggle="logo-sidebar"
                                    aria-controls="logo-sidebar"
                                    type="button"
                                    className="lg:hidden inline-flex items-center p-2 text-sm rounded hover:bg-[#131a2f] focus:outline-none focus:ring-1 text-gray-400 focus:ring-[#17243a]"
                                    onClick={() => setSidebarOpen(!sidebarOpen)}
                                >
                                    <span className="sr-only">
                                        Open sidebar
                                    </span>
                                    <svg
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                                        ></path>
                                    </svg>
                                </button>
                            ) : (
                                <NewChatBtn />
                            )}
                            <a className="flex ms-2 md:me-24">
                                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                                    YanzGPT
                                </span>
                            </a>
                        </div>
                        <div className="gap-4 flex h-full w-auto flex-row p-1 justify-between items-center">
                            {isAuth ? (
                                <>
                                    <NewChatBtn />

                                    <button
                                        type="button"
                                        className="flex justify-center items-center w-8 h-8 text-lg p-1.5 rounded hover:bg-[#131a2f] focus:outline-none focus:ring-1 text-gray-400 hover:bg-[#131a2f] focus:ring-[#17243a]"
                                        aria-expanded="false"
                                        onClick={() =>
                                            setUserMenuOpen(!userMenuOpen)
                                        }
                                    >
                                        <i className="fa-solid fa-ellipsis-vertical"></i>
                                    </button>
                                </>
                            ) : (
                                <button
                                    type="button"
                                    onClick={() => navigate("/login")}
                                    className="flex items-center justify-center text-white font-medium rounded-full text-sm px-4 py-2 text-center bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700 transition duration-300"
                                >
                                    Sign in
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                {temporaryChat && (
                    <div
                        onClick={() => {
                            setUserMenuOpen(!userMenuOpen);
                            setModalShow(!modalShow);
                        }}
                        className="top-1.5 text-[#384e92] bg-[#1d284981] w-full h-[50px] flex items-center justify-center "
                    >
                        <div className="flex flex-row items-center justify-between gap-2">
                            <i class="text-sm fa-solid fa-comment-slash"></i>
                            <h3>Obrolan sementara</h3>
                            <i class="text-sm fa-regular fa-circle-question"></i>
                        </div>
                    </div>
                )}
                {modalShow && (
                    <Modal
                        title="Obrolan Sementara"
                        onHide={() => setModalShow(false)}
                    >
                        <dl class="max-w-md divide-y divide-[#17243a]">
                            <div class="flex flex-col pt-3 pb-3">
                                <dt class="font-semibold mb-1 md:text-lg">
                                    Tanpa Memori
                                </dt>
                                <dd class="text-sm">
                                    YanzGPT tidak akan menyimpan memori pesan
                                    sebelumnya, jika anda menyuruh model
                                    mengingat sesuatu, model tidak akan
                                    mengingat nya
                                </dd>
                            </div>
                            <div class="flex flex-col pt-3 pb-3">
                                <dt class="font-semibold mb-1 md:text-lg">
                                    Tanpa System Prompt
                                </dt>
                                <dd class="text-sm">
                                    Obrolan sementara tidak akan menggunakan
                                    instruksi kustom dari anda
                                </dd>
                            </div>
                            <div class="flex flex-col pt-3 pb-3">
                                <dt class="font-semibold mb-1 md:text-lg">
                                    Riwayat Tidak Di Simpan
                                </dt>
                                <dd class="text-sm">
                                    Obrolan tidak akan di simpan di riwayat
                                    obrolan
                                </dd>
                            </div>
                        </dl>
                    </Modal>
                )}
                {userMenuOpen && (
                    <div className="rounded bg-[#131a2f] flex flex-col gap-1 justify-center transition-all transition-[height] duration-200 ease-out w-[200px] absolute right-1 top-[65px] border border-[#17243a]">
                        <ul className="list-none !m-0 !p-0">
                            {userMenu.map((menu, index) => {
                                return (
                                    <li
                                        key={index}
                                        onClick={menu.onClick}
                                        className="h-[50px] flex gap-3 flex-row items-center hover:bg-[#17243a] focus:bg-[#17243a] !m-0 !p-2.5 w-full"
                                    >
                                        <i className={menu.icon}></i>
                                        <p className="!m-0">{menu.title}</p>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </nav>
        </>
    );
};
