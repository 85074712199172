import React from "react";

const ChatButton = () => {
    let logo = process.env.PUBLIC_URL + "/yanz.png";
    return (
        <a href="/chat" className="chat-button fixed bottom-8 right-8 z-50">
            <img
                src={logo}
                alt="Chat"
                className="w-16 h-16 rounded-full hover:scale-110 transition-transform duration-300 shadow-lg"
            />
        </a>
    );
};

export default ChatButton;
